/**
 * Reinados scene utils
 *
 */

export const reinados = [
	{
		"firstRegent": "oxum",
		"firstRegentName": "Oxum",
		"secondRegent": "xango",
		"secondRegentName": "Xangô",
		"year": 2021
	},
	{
		"firstRegent": "xango",
		"firstRegentName": "Xangô",
		"secondRegent": "ogum",
		"secondRegentName": "Ogum",
		"year": 2020
	},
	{
		"firstRegent": "iemanja",
		"firstRegentName": "Iemanjá",
		"secondRegent": "iroco",
		"secondRegentName": "Iroco",
		"year": 2019
	},
	{
		"firstRegent": "iemanja",
		"firstRegentName": "Iemanjá",
		"secondRegent": "ogum",
		"secondRegentName": "Ogum",
		"year": 2018
	},
	{
		"firstRegent": "oxala",
		"firstRegentName": "Oxalá",
		"secondRegent": "africano",
		"secondRegentName": "Africanos",
		"year": 2017
	},
	{
		"firstRegent": "oxum",
		"firstRegentName": "Oxum",
		"secondRegent": "xango",
		"secondRegentName": "Xangô",
		"year": 2016
	},
	{
		"firstRegent": "xango",
		"firstRegentName": "Xangô",
		"secondRegent": "oxossi",
		"secondRegentName": "Oxossi",
		"year": 2015
	},
	{
		"firstRegent": "oxossi",
		"firstRegentName": "Oxossi",
		"secondRegent": "ogum",
		"secondRegentName": "Ogum",
		"year": 2014
	},
	{
		"firstRegent": "ogum",
		"firstRegentName": "Ogum",
		"secondRegent": "iemanja",
		"secondRegentName": "Iemanjá",
		"year": 2013
	},
	{
		"firstRegent": "oxala",
		"firstRegentName": "Oxalá",
		"secondRegent": "africano",
		"secondRegentName": "Affricanos",
		"year": 2012
	},
	{
		"firstRegent": "africano",
		"firstRegentName": "Africanos",
		"secondRegent": "iemanja",
		"secondRegentName": "Iemanjá",
		"year": 2011
	},
	{
		"firstRegent": "iemanja",
		"firstRegentName": "Iemanjá",
		"secondRegent": "iansa",
		"secondRegentName": "Iansã",
		"year": 2010
	},
	{
		"firstRegent": "xango",
		"firstRegentName": "Xangô",
		"secondRegent": "oxossi",
		"secondRegentName": "Oxossi",
		"year": 2009
	},
	{
		"firstRegent": "ogum",
		"firstRegentName": "Ogum",
		"secondRegent": "bara",
		"secondRegentName": "Bará",
		"year": 2008
	},
	{
		"firstRegent": "iemanja",
		"firstRegentName": "Iemanjá",
		"secondRegent": "ogum",
		"secondRegentName": "Ogum",
		"year": 2007
	},
	{
		"firstRegent": "oxossi",
		"firstRegentName": "Oxossi",
		"secondRegent": "",
		"secondRegentName": "",
		"year": 2005
	},
	{
		"firstRegent": "xango",
		"firstRegentName": "Xangô",
		"secondRegent": "",
		"secondRegentName": "",
		"year": 2004
	},
	{
		"firstRegent": "omulu",
		"firstRegentName": "Xapanã",
		"secondRegent": "",
		"secondRegentName": "",
		"year": 2003
	}
]