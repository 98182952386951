/**
 * Frontpage scene utils
 *
 */
import React from 'react'
import styled from 'styled-components'

import clouds from 'assets/images/clouds.jpg'
import teide from 'assets/images/teide.jpg'

import logoBig from 'assets/icons/logoBig.png'

const Logo = styled.img`
	width: 240px;
	margin-top: -50px;
	height: auto;
`

const BgImage = styled.div`
	width:100%;
	height:100%;
	background-image: url(${({ src }) => src});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

const LogoWrapper = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(58, 84, 128, 0.5);
`

export const items = [
	{
		content: (
			<>
				<LogoWrapper>
					<Logo src={logoBig} alt='Umbanda' />
				</LogoWrapper>
				<BgImage src={clouds} />
			</>

		),
		caption: "Umbanda Tenerife",
		subCaption: "Servicio con amor a la humanidad"
	},
	{
		content: <BgImage src={teide} />,
		caption: "Visitenos",
		subCaption: "En el norte de la isla"
	}
]
