/**
 * Oraciones scene
 *
 */
import React, { useState } from 'react'
import styled from 'styled-components'
import {
	useRouteMatch,
	Switch,
	Route,
	Link
} from "react-router-dom";
import { Row, Col, Container, Nav, NavItem } from "reactstrap";
import { Sticky } from 'react-sticky';

import Story from 'containers/Story'


//import config from 'config'
import { oraciones } from './utils'

const Oraciones = ({ t, i18n }) => {
	const [selected, setSelected] = useState(0)

	let { path, url } = useRouteMatch();

	return (
		<Row>
			<Col lg="2" md="3">
				<Sticky>
					{({ style, distanceFromTop }) => (
						<div style={{ ...style, top: '130px' }}>
							<NavBar vertical>
								{oraciones.map((oracion, key) => {
									return (
										<NavBarItem selected={selected === key} key={`oracion_lista_${key}`}>
											{
												oracion.url && <NavBarLink onClick={() => setSelected(key)} to={`${url}/${oracion.url}`}>{oracion.name}</NavBarLink>
											}
											{
												!oracion.url && <NavBarLink onClick={() => setSelected(key)} to={url}>{oracion.name}</NavBarLink>
											}
										</NavBarItem>)
								})}
							</NavBar>
						</div>
					)}
				</Sticky>
			</Col>
			<Col lg="10" md="9">
				<Switch>
					{oraciones.map((oracion, key) => {
						if (oracion.url) {
							return (
								<Route
									key={`oracion_lista2_${key}`}
									render={() => <Story
										title={oracion.name}
										body={oracion.content}
									/>}
									path={`${path}/${oracion.url}`}
								/>
							)
						} else {
							return (
								<Route
									key={`oracion_lista2_${key}`}
									exact path={path}
									render={() => <Story
										title={oracion.name}
										body={oracion.content}
									/>}
								/>
							)
						}
					})}
				</Switch>
			</Col>
		</Row>
	)
}

export default Oraciones

const NavBar = styled(Nav)`
  line-height: 25px;
  letter-spacing: -1px;
  color: #333333;
`

const NavBarItem = styled(NavItem)`
  a {
    color: #7d7d7d;
    font-weight:600;

    &:hover{
      color: #3e9bae;
    }
  }

  ${({ selected }) => selected &&
		`
      font-size: 1.3rem;
      border-left: 4px solid #3e9bae;
      a {
        color: #3e9bae;
       font-weight: 900;
      }
      
    `
	}
`

const NavBarLink = styled(Link)`
  display: block;
  text-decoration: none !important;
  padding: 0.5rem 1rem;
  background-color: transparent;
`