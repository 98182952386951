/**
 * Templo scene
 *
 */
import React from 'react'
import styled from 'styled-components'
import {
  Container,
  Row,
  Col
} from "reactstrap";
import Story from 'containers/Story'

import Title from "components/Title";

import patternBg from 'assets/images/pattern.png';

import { body } from './utils'

//import config from 'config'

const Templo = ({ t, i18n, screenType }) => {

  return (
    <Wrapper>
      <Container className="mt-5 pt-5 ">
        <Title>
          {t("TEMPLE_TITLE")}
        </Title>
        <Story
          body={body(t, screenType)}
        />
      </Container>

      <div className="frontpage__videos">
        <div className="frontpage__videos-pattern" />
        <Container>
          <Row className="mt-5 mb-5">
            <Col className="frontpage__video">
              <div>
                <iframe
                  height="216"
                  src="https://www.youtube.com/embed/S4nv9L2yc0M"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div> {t("FRONTPAGE_VIDEOS_1")}</div>
              </div>
            </Col>
            <Col className="frontpage__video">
              <div>
                <iframe
                  height="216"
                  src="https://www.youtube.com/embed/6otQp8l6iFI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div>{t("FRONTPAGE_VIDEOS_2")}</div>
              </div>
            </Col>
            <Col className="frontpage__video">
              <div>
                <iframe
                  height="216"
                  src="https://www.youtube.com/embed/uu3zX5WWQc8"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div>{t("FRONTPAGE_VIDEOS_3")}</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >

    </Wrapper>
  )
}

export default Templo

const Wrapper = styled.div`
.frontpage__videos {
  position: relative;
  background: linear-gradient(-45deg, #3377a9 50%, #41a7b1 107%);
  padding: 2px;
  color: white;
  text-shadow: 2px 2px #5d5d5d;

  .frontpage__videos-pattern {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-image: url(${patternBg});
    background-size: 100px 100px;
    opacity: 0.8;
    -webkit-mask-image: radial-gradient(
      ellipse at top right,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  iframe {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
      0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .frontpage__video {
    justify-content: center;
    display: flex;
    z-index: 1;

    div{
      text-align:left;
    }
  }
`