/**
 * Map container
 *
 */

import React, { useState, useRef } from 'react'
import ReactMapboxGl, { Marker, Popup } from 'react-mapbox-gl';
import styled from 'styled-components'
import { FiPhone, FiExternalLink, FiMapPin } from "react-icons/fi";

//import config from 'config'
import logoSmall from "assets/icons/logoSmall.png";

const secret = 'sk.eyJ1IjoiZG14cmlkZSIsImEiOiJjazl6cnJlZnYweTR4M21saTJ6bmVxa24zIn0.3z9EI0fvvKSJUFTiiS9rZA'
const MapboxMap = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiZG14cmlkZSIsImEiOiJjazl6cnFoNHgwMjl4M2l0NnA2MHEwNTIwIn0.ytmzRLJLxE3fjVYHSW0uEg'
});

const Map = ({ screenSize, interactive, dragPan, initialZoom, temples, centerCoord }) => {
  let mapRef = useRef(null)

  const [zoom, setZoom] = useState(initialZoom | 2)
  const [center, setCenter] = useState(centerCoord || [-35.8241176, 15.1981785])
  const [temple, setTemple] = useState(null)

  return (
    <Wrapper>
      <MapboxMap
        interactive={interactive}
        dragPan={dragPan}
        onMouseDown={() => {
          setTemple(null)
        }}
        style="mapbox://styles/dmxride/ck9zryp4o13qq1ipb9sw8ixw9"
        containerStyle={{
          height: `${screenSize.height}px`,
          width: `100%`,
        }}
        zoom={[zoom]}
        onZoom={(map) => setZoom(map.getZoom())}
        onStyleLoad={map => {
          mapRef.current = map
          map.resize()
        }}
        center={center}
      >
        {temples.map((temple, key) =>
          <div key={`temple_map_marker_${key}`}>
            <Marker coordinates={temple.coordinates}>
              <Mark
                src={logoSmall}
                onMouseOver={() => {
                  setTemple(temple)
                }}
                onClick={() => {
                  setTemple(temple)
                  mapRef.current && mapRef.current.flyTo({
                    center: temple.coordinates,
                    zoom: 11,
                  }
                  )
                }}
              />
            </Marker>
          </div>
        )}

        {temple &&
          <PopupWrapper
            coordinates={temple.coordinates}
            offset={{
              'bottom-left': [12, -38], 'bottom': [0, -38], 'bottom-right': [-12, -38]
            }}>
            <PopupTitle>{temple.name}</PopupTitle>
            <PopupDesc>
              <div style={{ marginBottom: '20px' }}>
                {temple.details}
              </div>
              {temple.contact &&
                <div style={{ marginBottom: '5px' }}>
                  <FiPhone /> <a href={`tel:${temple.contact}`}>{temple.contact}</a>
                </div>
              }
              <div style={{ marginBottom: '5px' }}>
                <FiMapPin /> <a target="_blank" href={`https://www.google.pt/maps/place/${temple.coordinates[1]},${temple.coordinates[0]}`}>{`${temple.coordinates[1]},${temple.coordinates[0]}`}</a>
              </div>
              {temple.url &&
                <div>
                  <FiExternalLink /> <a target="_blank" href={temple.url}>Visitar web</a>
                </div>
              }
            </PopupDesc>
          </PopupWrapper>}

      </MapboxMap>
    </Wrapper>
  )
}

export default Map

const Wrapper = styled.div`
  width: 100%;

  a{
    color: #333;
  }
  
  svg{
    color:  #51b68c;
  }
`

const PopupWrapper = styled(Popup)`
  width: 300px;
`

const PopupTitle = styled.div`
  font-size: 16px;
  font-weight:bold;
  margin-bottom: 6px;
  color:  #51b68c;
`

const PopupDesc = styled.div`
  font-size: 13px;
`

const Mark = styled.img`
  width:40px;
  height:40px;
  border-radius:20px;
  cursor:pointer;
  padding:3px;
  z-index:1;
  background-color: rgba(255,255,255,0.5);
`