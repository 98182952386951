/**
 * Frontpage scene
 *
 */
import React, { useState } from 'react'
import styled from 'styled-components'

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
  Row,
  Col
} from "reactstrap";

import ColorBanner from 'containers/ColorBanner'

import FrontPageTitle from 'components/FrontPageTitle'
import Anchor from 'components/Icons/anchor'
import Cross from 'components/Icons/cross'
import Heart from 'components/Icons/heart'

import patternBg from 'assets/images/pattern.png';

//import config from 'src/config'

import { items } from './utils'

const Frontpage = ({ t, i18n }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  return (
    <Wrapper>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators items={items} onClickHandler={goToIndex} />
        {items.map((item, key) =>
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={`Carousel_frontPage_${key}`}
          >
            {item.content}
            <CarouselText captionText={item.subCaption} captionHeader={item.caption} />
          </CarouselItem>
        )}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>

      <Container className="pb-5 pt-5">
        <Row className="pb-3">
          <Col>
            <FrontPageTitle>
              {t("FRONTPAGE_INTRO_TITLE")}
            </FrontPageTitle>
            <div className="mb-4">
              {t("FRONTPAGE_INTRO_DESC_1")}
            </div>
            <div>
              {t("FRONTPAGE_INTRO_DESC_2")}
            </div>
          </Col>
        </Row>
      </Container>

      {/*<div className="frontpage__videos">
        <div className="frontpage__videos-pattern" />
        <Container>
          <Row className="mt-5 mb-5">
            <Col className="frontpage__video">
              <div>
                <iframe
                  height="216"
                  src="https://www.youtube.com/embed/S4nv9L2yc0M"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div> {t("FRONTPAGE_VIDEOS_1")}</div>
              </div>
            </Col>
            <Col className="frontpage__video">
              <div>
                <iframe
                  height="216"
                  src="https://www.youtube.com/embed/6otQp8l6iFI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div>{t("FRONTPAGE_VIDEOS_2")}</div>
              </div>
            </Col>
            <Col className="frontpage__video">
              <div>
                <iframe
                  height="216"
                  src="https://www.youtube.com/embed/uu3zX5WWQc8"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div>{t("FRONTPAGE_VIDEOS_3")}</div>
              </div>
            </Col>
          </Row>
        </Container>
        </div > */}

      <Caption className="frontpage__videos">
        <div className="frontpage__videos-pattern" />

        {t("FRONTPAGE_VIRTUES_QUOTE")}
      </Caption>


      <BottomArea>

        <Container className="pt-5 pb-5">
          <FrontPageTitle>
            {t("FRONTPAGE_VIRTUES_TITLES")}
          </FrontPageTitle>

          <ColorBanner
            title={t("FRONTPAGE_VIRTUES_FAITH_TITLE")}
            description={t("FRONTPAGE_VIRTUES_FAITH_DESC")}
            color="#f2c400"
            icon={<Cross />}
          />

          <ColorBanner
            title={t("FRONTPAGE_VIRTUES_HOPE_TITLE")}
            description={t("FRONTPAGE_VIRTUES_HOPE_DESC")}
            color="#1aae5c"
            icon={<Anchor />}
          />

          <ColorBanner
            title={t("FRONTPAGE_VIRTUES_CHARITY_TITLE")}
            description={t("FRONTPAGE_VIRTUES_CHARITY_DESC")}
            color="#e94a34"
            icon={<Heart />}
          />
        </Container>
      </BottomArea>


      <Container className="pt-5 pb-5">

        <FrontPageTitle>
          {t("FRONTPAGE_PILARS_TITLE")}
        </FrontPageTitle>
        <List>
          <li>
            {t("FRONTPAGE_PILARS_1")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_2")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_3")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_4")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_5")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_6")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_7")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_8")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_9")}
          </li>
          <li>
            {t("FRONTPAGE_PILARS_10")}
          </li>
        </List>
      </Container>




    </Wrapper >
  );
}

export default Frontpage

const Wrapper = styled.div`
.carousel {
  max-width: 100%;
  height: 500px;
  .carousel-item {
    height: 500px;
  }
}
.frontpage__videos {
  position: relative;
  background: linear-gradient(-45deg, #3377a9 50%, #41a7b1 107%);
  padding: 42px 0;
  color: white;
  //text-shadow: 2px 2px #5d5d5d;

  .frontpage__videos-pattern {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-image: url(${patternBg});
    background-size: 100px 100px;
    opacity: 0.6;
    -webkit-mask-image: radial-gradient(
      ellipse at top right,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  iframe {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
      0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .frontpage__video {
    justify-content: center;
    display: flex;
    z-index: 1;

    div{
      text-align:left;
    }
  }
}
`

const List = styled.ol`
  list-style: none;
  counter-reset: counter;

  li{
    counter-increment: counter;
    line-height:32px;
  }

  li:not(:last-child) { 
   margin-bottom:30px;
} 

  li::before {
    content: counter(counter) ". ";
    font-weight: bold;
    font-size: 30px;
  }
  
`

const CarouselText = styled(CarouselCaption)`
  &.carousel-caption{
    font-size: 20px;

    h3{
      text-transform:uppercase;
      font-size: 40px;
      letter-spacing: -2.5px;
      font-weight: 900;
    }
   
  }
`

const BottomArea = styled.div`
background-color: #fdfdfd;
border-bottom: 7px solid #3e9bae;
`

const Highlight = styled.div`
 
`



const HighlightList = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
 
  div:not(:last-child) { 
    margin-bottom:10px;
  } 
`

const HighlightDesc = styled.div`
  color: #fff;
  font-weight: bold;
  margin-bottom: 12px;
`

const HighlightListTitle = styled.div`
  color: #b8e3ff;
  font-size: 1.2rem;
  font-weight: bold;
`

const HighlightTitle = styled.div`
  color: #fff;
  font-weight: bold;
  text-align:center;
  line-height: 1.5em !important;
  letter-spacing: 2px;
  font-size: 40px;
  margin-bottom: 40px;
  border-bottom: 3px solid #3e9bae;
`

const Caption = styled.div`
  font-family: 'Alegreya';
  font-style: italic;
  letter-spacing: 2px;
  font-size: 40px;
  color: white;
  background: linear-gradient(-45deg, #3377a9 50%, #41a7b1 107%);
  padding: 60px 0;
  text-align: center;
  position:relative; 


  &:before {
    font-family: 'Times New Roman';
    position:absolute; 
    content: open-quote;
    font-weight: bold;
    font-size: 230px;
    margin-top: 30px;
    margin-left: -45px;
    opacity:0.1;
  } 

  &:after {
    font-family: 'Times New Roman';
    position:absolute; 
    content: close-quote;
    font-weight: bold;
    font-size: 230px;
    margin-top: 60px;
    margin-left: -110px;
    opacity:0.1;
  } 

`