export default {
	name: 'A San Francisco',
	url: 'sanfrancisco',
	content: [
		{
			type: 'content',
			text: 'Senhor, fazei-me um instrumento de vossa paz. Onde houver ódio, que eu leve o amor, onde houver ofensa, que eu leve o perdão, onde houver discórdia, que eu leve a união, onde houver dúvidas, que eu leve a fé, onde houver erro, que eu leve a verdade, onde houver desespero, que eu leve a esperança onde houver tristeza, que eu leve alegria, onde houver trevas, que eu leve a luz.'
		},
		{
			type: 'content',
			text: 'Mestre, fazei que eu procure mais consolar do que ser consolado, compreender do que ser compreendido, amar do que ser amado.'
		},
		{
			type: 'content',
			text: 'Pois é dando que se recebe, é perdoando que se é perdoado, é morrendo que se vive para a vida eterna.'
		},

	]
}