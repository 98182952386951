/**
 * App sagas
 * Please write a description
 *
 */

import { call, takeLatest } from 'redux-saga/effects';

//import { actions } from 'store/rootSlices';

import config from 'config';
import { history } from 'utils';

function* onMountSaga({ payload }) {
}

function* navigateToSaga({ payload }) {
  yield call(history.push, payload);
}

function* navigateBackSaga({ payload }) {
  yield call(history.goBack);
}

function* updateUserRoleSaga({ payload }) {
  //alert('Update user role')
}

export default function* watcherSignin() {
  yield takeLatest('App/onMount', onMountSaga);
  yield takeLatest('App/navigateTo', navigateToSaga);
  yield takeLatest('App/navigateBack', navigateBackSaga);
  yield takeLatest('App/updateUserRole', updateUserRoleSaga);
}
