
import React from 'react'


export default (props) => {
	return (
		<>
			<svg viewBox="0 0 431.688 431.688" {...props}>
				<path d="M253.612 431.688h-75.536V178.752H74.86v-75.536h103.216V0h75.536v103.216h103.216v75.536H253.612v252.936zm-54.536-21h33.536V157.752h103.216v-33.536H232.612V21h-33.536v103.216H95.86v33.536h103.216v252.936z" />
			</svg>
		</>
	)
}