/**
 * Chakras scene
 *
 */
import React, { useState } from 'react'
import styled from 'styled-components'
import {
	useRouteMatch,
	Switch,
	Route,
	Link
} from "react-router-dom";
import { Row, Col, Nav, NavItem } from "reactstrap";
import { Sticky } from 'react-sticky';

import Story from 'containers/Story'

//import config from 'config'
import { chakras } from './utils'

const Chakras = ({ i18n }) => {
	const [selected, setSelected] = useState(0)

	let { path, url } = useRouteMatch();

	return (
		<Row>
			<Col lg="2" md="3">
				<Sticky>
					{({ style, distanceFromTop }) => (
						<div style={{ ...style, top: '130px' }}>
							<NavBar vertical>
								{chakras(i18n).map((chakra, key) => {
									return (
										<NavBarItem selected={selected === key} key={`chakra_lista_${key}`}>
											{
												chakra.url && <NavBarLink onClick={() => setSelected(key)} to={`${url}/${chakra.url}`}>{chakra.name}</NavBarLink>
											}
											{
												!chakra.url && <NavBarLink onClick={() => setSelected(key)} to={url}>{chakra.name}</NavBarLink>
											}
										</NavBarItem>)
								})}
							</NavBar>
						</div>
					)}
				</Sticky>
			</Col>
			<Col lg="10" md="9">
				<Switch>
					{chakras(i18n).map((chakra, key) => {
						if (chakra.url) {
							return (
								<Route
									key={`chakra_lista2_${key}`}
									render={() => <Story
										title={chakra.name}
										body={chakra.content}
									/>}
									path={`${path}/${chakra.url}`}
								/>
							)
						} else {
							return (
								<Route
									key={`chakra_lista2_${key}`}
									exact path={path}
									render={() => <Story
										title={chakra.name}
										body={chakra.content}
									/>}
								/>
							)
						}
					})}
				</Switch>
			</Col>
		</Row>
	)
}

export default Chakras


const NavBar = styled(Nav)`
  line-height: 25px;
  letter-spacing: -1px;
  color: #333333;
`

const NavBarItem = styled(NavItem)`
  a {
    color: #7d7d7d;
    font-weight:600;

    &:hover{
      color: #3e9bae;
    }
  }

  ${({ selected }) => selected &&
		`
      font-size: 1.3rem;
      border-left: 4px solid #3e9bae;
      a {
        color: #3e9bae;
       font-weight: 900;
      }
      
    `
	}

`

const NavBarLink = styled(Link)`
  display: block;
  text-decoration: none !important;
  padding: 0.5rem 1rem;
  background-color: transparent;
`