/**
 * Oraciones scene
 *
 */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Container } from "reactstrap";
import { StickyContainer } from 'react-sticky';


import Title from "components/Title";

//import config from 'config'
import Web from "./web";
import Mobile from "./mobile";

const Oraciones = ({ t, screenType, ...props }) => {

  return (
    <StickyContainer>
      <Wrapper className="mt-5 pt-3">
        <Title>{t("PRAYERS_TITLE")}</Title>
        <Container>
          {screenType === 'web' && <Web {...props} />}
          {screenType === 'mobile' && <Mobile  {...props} />}
        </Container>
      </Wrapper >
    </StickyContainer>
  )
}

export default Oraciones

const Wrapper = styled(Container)`
`