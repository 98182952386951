/**
 * Chakras scene
 *
 */
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  useRouteMatch,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Row, Col, Container, Nav, NavItem } from "reactstrap";
import { StickyContainer } from 'react-sticky';


import Title from "components/Title";

//import config from 'config'
import Web from "./web";
import Mobile from "./mobile";

const Chakras = ({ t, i18n, screenType }) => {

  return (
    <StickyContainer>
      <Wrapper className="mt-5 pt-3">
        <Title>
          Chakras
       </Title>
        <Container>
          {screenType === 'web' && <Web i18n={i18n} />}
          {screenType === 'mobile' && <Mobile i18n={i18n} />}
        </Container>
      </Wrapper >
    </StickyContainer>
  )
}

export default Chakras

const Wrapper = styled(Container)`
`