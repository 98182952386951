/**
 * Templos scene
 *
 */
import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'

import Map from 'containers/Map'
import { temples } from './utils'

//import config from 'config'

const Templos = ({ screenSize, t, i18n }) => {
  const [showMap, setShowMap] = useState(false)
  useLayoutEffect(() => {
    setShowMap(true)
  }, [])

  return (
    <Wrapper>
      {showMap && <Map temples={temples} screenSize={screenSize} />}
    </Wrapper>
  )
}

export default Templos

const Wrapper = styled.div`
  height: 100%;
  margin-bottom: -60px;
`