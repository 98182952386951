/**
 * Reinados scene
 *
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Container } from "reactstrap";
import { GoogleSpreadsheet } from "google-spreadsheet";

import Title from "components/Title";
import { reinados as _reinados } from "./utils";
import Loader from "components/Loader";

//import config from 'config'

const Reinados = ({ t, i18n, screenSize }) => {
  //const [loading, setLoading] = useState([_reinados])
  const [reinados, setReinados] = useState([_reinados]);
  const [loading, setLoading] = useState(true);

  const initClient = async () => {
    try {
      const doc = new GoogleSpreadsheet(
        "1gsK2GwBHM5Py0T3RLjpgQyRjkCYsq73z7KGovedcrD4"
      );
      await doc.useApiKey("AIzaSyDGeKZyUYi6w4iJA_D8ETzTFGuFDpC6xaI");
      await doc.loadInfo(); // loads document properties and worksheets
      let rows = await doc.sheetsByIndex[0].getRows();
      rows = rows.sort((a, b) => b?.year - a?.year);
      setReinados(rows);
      setLoading(false);
    } catch (e) {}
  };

  const driveId = (driveName) => {
    const drive = "https://drive.usercontent.google.com/download?id=";
    const regMatch = driveName.match(/[-\w]{25,}/);
    if (regMatch) {
      return drive + regMatch[0];
    } else {
      throw new Error();
    }
  };

  useEffect(() => {
    initClient();
  }, []);

  const setRImage = (img, imgLink) => {
    try {
      let ext = "png";
      if (
        img === "africano" ||
        img === "bara" ||
        img === "oxala" ||
        img === "pomba" ||
        img === "pretoVelho"
      )
        ext = "jpg";
      return (
        <RightImg
          src={require(`assets/images/orixas/${img}.${ext}`)}
          alt={`reinado_${img}`}
          screenSize={screenSize}
        />
      );
    } catch (e) {
      return (
        <RightImg src={``} alt={`reinado_${img}`} screenSize={screenSize} />
      );
    }
  };

  const setLImage = (img, imgLink) => {
    try {
      let ext = "png";
      if (
        img === "africano" ||
        img === "bara" ||
        img === "oxala" ||
        img === "pomba" ||
        img === "pretoVelho"
      )
        ext = "jpg";
      return (
        <LeftImg
          src={require(`assets/images/orixas/${img}.${ext}`)}
          alt={`reinado_${img}`}
          screenSize={screenSize}
        />
      );
    } catch (e) {
      return (
        <LeftImg src={``} alt={`reinado_${img}`} screenSize={screenSize} />
      );
    }
  };

  return (
    <Wrapper className="mt-5 pt-3">
      <Title>{t("REIGNS_TITLE")}</Title>
      {loading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {reinados.map((reinado) => (
        <KingdomRow lg="4" xs="4" md="4">
          <ImgWrapper>
            {setLImage(reinado.firstRegent, reinado.firstRegentImage)}
          </ImgWrapper>
          <Year>
            {reinado.firstRegentName && (
              <LNameTop screenSize={screenSize}>
                {reinado.firstRegentName}
              </LNameTop>
            )}
            <YearWrapper>{reinado.year && reinado.year}</YearWrapper>
            {reinado.secondRegentName && (
              <RNameTop screenSize={screenSize}>
                {reinado.secondRegentName}
              </RNameTop>
            )}
          </Year>
          {reinado.secondRegent && (
            <ImgWrapper>
              {setRImage(reinado.secondRegent, reinado.secondRegentImage)}
            </ImgWrapper>
          )}
        </KingdomRow>
      ))}
    </Wrapper>
  );
};

export default Reinados;

const Wrapper = styled(Container)``;

const LoaderWrapper = styled.div`
  margin: 140px auto;
  display: flex;
  justify-content: center;
`;

const LeftImg = styled.div`
  height: 100%;
  width: ${({ screenSize }) => (screenSize.width < 990 ? "90%" : "80%")};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const RightImg = styled.div`
  height: 100%;
  width: ${({ screenSize }) => (screenSize.width < 990 ? "960%" : "80%")};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const KingdomRow = styled(Row)`
  margin-bottom: 5rem;
  justify-content: center;
`;

const ImgWrapper = styled.div`
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Year = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2rem;
  position: relative;

  font-weight: bold;
  text-align: center;
  line-height: 1.5em !important;
  letter-spacing: 2px;
  font-size: 3.4em;
`;

const YearWrapper = styled.div`
  z-index: 3;
`;

const LNameTop = styled.div`
  position: absolute;
  left: ${({ screenSize }) => (screenSize.width < 990 ? "-3rem;" : "-4rem;")};
  z-index: 2;

  top: -20px;
  text-align: left;

  font-weight: bold;
  text-align: center;
  letter-spacing: 2px;
  font-size: ${({ screenSize }) =>
    screenSize.width < 990 ? "0.7em" : "1.1em"};
  color: #7e7e7e;
  padding: 20px;

  opacity: 0.5;
  color: black;
`;

const RNameTop = styled.div`
  position: absolute;
  right: ${({ screenSize }) => (screenSize.width < 990 ? "-3rem;" : "-4rem;")};
  bottom: -20px;
  text-align: right;
  padding: 20px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 2px;
  font-size: ${({ screenSize }) =>
    screenSize.width < 990 ? "0.7em" : "1.1em"};
  opacity: 0.5;
  color: black;
`;
