export default {
	name: 'Y Ojos Cerrados',
	url: 'ojoscerrados',
	content: [
		{
			type: 'content',
			text: 'Por Cassio Ribeiro (Umbandista, profesional de radio y presidente de Fucabrad)'
		},
		{
			type: 'content',
			text: 'Sentado delante de su congá un viejo Pai de Santo se recuerda con sorprendente nitidez su infancia y su primero contacto con la espiritualidad. Nítidamente se ve en la tierna infancia a jugar solo en el amplio patio de la casa de sus padres. Se acuerda que alguna cosa le hizo mirar para las nubes y delante de él se formó una extraña imagen: un viejo sentado alrededor de una hoguera y un niño escuchando historias. De alguna forma el niño cuando vio la imagen sabía que se trataba de él mismo.'
		},
		{
			type: 'content',
			text: 'El tiempo pasó y la imagen jamás olvidada y tampoco revelada, le acompaña en sueños y recuerdos. Crece y en fin tornase médium Umbandista. Poco a poco conoce sus guías, que toman su cuerpo en las muchas "giras de desenvolvimiento".'
		},
		{
			type: 'content',
			text: 'Primero el Caboclo que le parece grande y fuerte, después los demás, hasta que a los 18 años su Exu también recibe permiso para incorporar. Ya no es más médium de giramiento a bien de la verdad ocupa el cargo de Pai Pequeno de terreiro.'
		},
		{
			type: 'content',
			text: 'Se da cuenta de que no tuvo la misma adolescencia que los demás jóvenes del colegio. No va a bailes, fiestas... Se dedica con una curiosidad y un amor cada vez mas grande en la práctica de la caridad. Los años pasan y a la final abre un terreiro propio.'
		},
		{
			type: 'content',
			text: 'Muchas personas buscan sus guías y reciben como un calmante, una palabra de consuelo y esperanza... Tantos fueron los pedido y tantos trabajos realizados que perdió las cuentas. Vio a muchas personas que declaraban amor eterno a Umbanda y bastaba que no se lograra algún pedido en la plenitud deseada que ya se apartaban criticando lo que ayer les era sagrado.'
		},
		{
			type: 'content',
			text: 'Fue testigo de personas que vinieron de otras religiones y que encontraron paz dentro del terreiro, mantenido a duras penas, una vez que no se cobraba nada por los trabajos realizados ("Dar gratuitamente lo que gratuitamente recibes").'
		},
		{
			type: 'content',
			text: 'Se permanecía soltero hasta la fecha, pues mismo de haber tenido muchas mujeres que le costaron caro, ninguna de ellas aguantó permanecer a su lado, pues para él, la vida sacerdotal se anteponía a cualquier relación.'
		},
		{
			type: 'content',
			text: 'Amaba así mismo todas aquellas que le hicieron compañía en su jornada terrenal. Jugaba, el viejo Pai de Santo, cuando le preguntaban se era casado y contestaba, de buen humor, que se había casado muy temprano, cuando era un niño. A curiosidad de los oyentes cuanto a quién era la esposa, la saciaba con una sola palabra: Umbanda, así se llamaba su esposa.'
		},
		{
			type: 'content',
			text: 'Con el pasar del tiempo, la edad fue llegando; muchos filhos de fe siguieron sus destinos llegando a abrir también sus casas de caridad.'
		},
		{
			type: 'content',
			text: 'El peso de la edad no le impide recibir sus entidades. Aún ecua, por el viejo y querido terreiro, el grito de su Caboclo, la pipa del Preto Velho perfuma el ambiente, la carcajada del Exú todavía impresiona, la alegría del Ere emociona a él y a todos...'
		},
		{
			type: 'content',
			text: 'En fin se siente útil al trabajar... Hoy no es día de giramiento. El terreiro está limpio, las velas están encendidas y todo parece normal. Resuelve adentrar al terreiro para pasar el tiempo, perdió la noción de la hora. Siente pasos a su alrededor, se da cuenta que alguien empieza a tocar los puntos y que toca el atabaque. Él está de espaldas para todo y delante del conga.'
		},
		{
			type: 'content',
			text: 'El olor de la defumación invade sus nariz...'
		},
		{
			type: 'content',
			text: 'Sus ojos se llenan de lágrimas en la misma proporción que su corazón se llena de alegría. Curiosamente, no tiene coraje o ganas de mirar para atrás, apenas canta junto los puntos'
		},
		{
			type: 'content',
			text: 'Fija su mirada en las imágenes del altar, cierra los ojos y aún así ve nítidamente el conga, parece darse cuenta que el movimiento del terreiro aumenta.'
		},
		{
			type: 'content',
			text: 'Voltease para el terreiro y la imagen le sorprende: ve caboclos, boiadeiros, pretos velhos, marujos, baianos, eres y toda gama de guías, hasta los Exus y Pomba Giras están allí en la puerta.'
		},
		{
			type: 'content',
			text: 'Se da cuenta que les ve como son, no están incorporados. Todos les sonríen con amabilidad. En medio a tantos guías, siente los que recibe en su materia desde que era un niño. Intenta batir coco en homenaje a ellos pero algo le impide. El caboclo, su guía de frente, se adelanta, le abraza, suelta su grito guerrero... los demás le acompañan. El viejo Pai de Santo no aguanta y llora emocionado... las lagrimas le turban la mirada. Cierra los ojos y cuando los abre todos los guías están en sus sitios, pero callados...'
		},
		{
			type: 'content',
			text: 'Siente una luz brillante en su dirección, Iansa y Omulú se acercan, su caboclo los saluda y es saludado. La luz le envuelve completamente. Ya no se siente viejo; mejor dicho, se siente joven como nunca antes. Su cuerpo esta ligero y se levanta en dirección a la luz. Todos los guías se reverencian. El terreiro se queda lejos y envuelto en una luz... él sonríe alegre, la misión estaba cumplida...'
		},
		{
			type: 'content',
			text: 'En el día siguiente, encuentran su cuerpo al pié del conga. Tenía en sus labios una sonrisa.'
		}
	]
}