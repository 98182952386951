export default {
	name: 'Credo Umbandista',
	url: 'credoumbandista',
	content: [
		{
			type: 'content',
			text: 'Creio em Olorum, onipotente e supremo.'
		},
		{
			type: 'content',
			text: 'Creio nos Orixás e nos espíritos divinos, que nos trouxeram para a vida por vontade deste majestuoso Pai.'
		},
		{
			type: 'content',
			text: 'Creio nas falanges espirituais orientando os homens na vida terrena.'
		},
		{
			type: 'content',
			text: 'Creio na lei da reencarnação e na justiça divina, segundo a lei do carma.'
		},
		{
			type: 'content',
			text: 'Creio na comunicação dos guias espirituais encaminhando-nos para a caridade e a prática do bem.'
		},
		{
			type: 'content',
			text: 'Creio na invocação, na prece e na oferenda como atos de fé e creio na Umbanda, como religião redentora capaz de nos levar pelo caminho da evolução até o nosso glorioso Pai Olorum.'
		},
		{
			type: 'content',
			text: 'Glória à Olorum.'
		},
		{
			type: 'content',
			text: 'Glória à Oxalá.'
		},
		{
			type: 'content',
			text: 'Glória aos Orixás.'
		},
		{
			type: 'content',
			text: 'E as entidades trabalhadoras da seara Umbandista.'
		},

	]
}