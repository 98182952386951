/**
 * Fiestas scene
 *
 */
import React from 'react'
import styled from 'styled-components'
import { Container } from "reactstrap";

import Title from "components/Title";

//import config from 'config'

const Fiestas = ({ t, i18n }) => {

  return (
    <Wrapper className="mt-5 pt-3">
      <Title className="h2 mb-5 text-center">
        {t("FESTIVITIES_TITLE")}
      </Title>
      <div className="d-flex justify-content-center">
        <img
          width="100%"
          height="auto"
          src={require("assets/images/calendario.png")}
          alt="templo2016"
        />
      </div>
    </Wrapper>
  )
}

export default Fiestas

const Wrapper = styled(Container)``