/**
 * Himno scene
 *
 */
import React from 'react'
import styled from 'styled-components'
import {
	Container,
	Row,
	Col
} from "reactstrap";
import Title from "components/Title";

import SerLuz from "assets/images/umbandista.png";

//import config from 'config'

const Mandamientos = ({ screenType, t, i18n }) => {

	return (
		<Wrapper className="mt-5 pt-3">
			<Title>{t("COMMANDMENTS_TITLE")}</Title>
			<Row>
				<ColLeft screenType={screenType} lg={4} md={6}>
					<Img src={SerLuz} alt={'Ser de Luz'} />

				</ColLeft>
				<ColRight screenType={screenType} lg={8} md={6}>
					<List screenType={screenType} >
						<li>
							{t("COMMANDMENTS_1")}
						</li>
						<li>
							{t("COMMANDMENTS_2")}
						</li>
						<li>
							{t("COMMANDMENTS_3")}
						</li>
						<li>
							{t("COMMANDMENTS_4")}
						</li>
						<li>
							{t("COMMANDMENTS_5")}
						</li>
						<li>
							{t("COMMANDMENTS_6")}
						</li>
						<li>
							{t("COMMANDMENTS_7")}
						</li>
					</List>
				</ColRight>
			</Row>
		</Wrapper>
	)
}

export default Mandamientos

const Wrapper = styled(Container)``

const List = styled.ol`
	${({ screenType }) => screenType === 'mobile' && `border-left: 3px solid #51b68c;`}

  list-style: none;
  counter-reset: counter;

  li{
		margin-left:-24px;
    counter-increment: counter;
    line-height:32px;
  }

  li:not(:last-child) { 
   margin-bottom:30px;
} 

  li::before {
    content: counter(counter) ". ";
    font-weight: bold;
    font-size: 30px;
  }
  
`

const ColLeft = styled(Col)`
${({ screenType }) => screenType !== 'mobile' && `padding-right:40px;`}

`
const ColRight = styled(Col)`
${({ screenType }) => screenType !== 'mobile' && `border-left: 2px solid #51b68c;`}
${({ screenType }) => screenType === 'mobile' && `margin-top: 20px;`}

`

const Img = styled.img`
	 max-height: 320px;
	 width: 100%;
`