import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  useLocation
} from "react-router-dom";

import Header from "containers/Header";
import Footer from "containers/Footer";

import theme from 'ui/theme';

import Routes from 'routes';

import { actions } from 'store/rootSlices';

function App(props) {
  const { t, i18n } = props;
  const { onMount, setScreenType } = actions.App;
  const [screenSize, setScreenSize] = useState({})

  const { user, screenType } = useSelector((state) => state.App);
  const dispatch = useDispatch();

  let wrapperRef = useRef(null)
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
    setScreenSize({ height: wrapperRef.current.clientHeight, width: wrapperRef.current.clientWidth })
  }, [location]);

  const eventListener = useCallback(() => {
    if (window.innerWidth <= 767) dispatch(setScreenType('mobile'));
    if (window.innerWidth > 767) dispatch(setScreenType('web'));
    setScreenSize({ height: wrapperRef.current.clientHeight, width: wrapperRef.current.clientWidth })
  }, [dispatch, setScreenType, setScreenSize]);

  useEffect(() => {
    dispatch(onMount());
    if (window.innerWidth <= 767) dispatch(setScreenType('mobile'));
    if (window.innerWidth > 767) dispatch(setScreenType('web'));
    setScreenSize({ height: wrapperRef.current.clientHeight, width: wrapperRef.current.clientWidth })
  }, [dispatch, onMount, setScreenType]);

  useEffect(() => {
    window.addEventListener('resize', eventListener);
    return () => window.removeEventListener('resize', eventListener);
  }, [eventListener]);

  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <Header t={t} i18n={i18n} />
        <Wrapper >
          <ContentWrapper ref={wrapperRef} >
            <Switch>
              {Routes.map(
                (route) =>
                  route.roles.indexOf(user.role) !== -1 && (
                    <Route
                      exact={route.exact}
                      key={route.key}
                      path={route.path}
                      render={() => <route.component theme={theme} screenType={screenType} screenSize={screenSize} t={t} i18n={i18n} />}
                    />
                  )
              )}
            </Switch>
          </ContentWrapper>
          <FooterWrapper>
            <Footer t={t} i18n={i18n} screenType={screenType} />
          </FooterWrapper>
        </Wrapper>
      </AppWrapper>
    </ThemeProvider>
  );
};

export default withTranslation()(App);

const AppWrapper = styled.div`
  font-family: 'IBM Plex Sans' !important;
  line-height: 1.75em !important;
`

const Wrapper = styled.div`
  min-height: 100vh;
  overflow-x:hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
`

const ContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.header.height};
  padding-bottom: 50px;
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
  background-color: rgba(241,241,233,0.5);
  
`
const FooterWrapper = styled.div`
order: 0;
  flex: 0 1 auto;  
  align-self: stretch;
`