
/**
 * Orixas scene
 *
 */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Row, Col, Container, Nav, NavItem, NavLink } from "reactstrap";
import { Sticky } from 'react-sticky';

import Orixa from "components/Orixa";

import patternBg from 'assets/images/pattern.png';

//import config from 'config'
import { orixas } from './utils'

const Orixas = ({ t, theme }) => {
	const [selected, setSelected] = useState(0)
	let lockVerification = useRef(false)

	const eventListener = useCallback((e) => {
		let limit = document.body.offsetHeight - window.innerHeight;
		if (!lockVerification.current) {

			if (window.scrollY === limit) {
				setSelected(orixas.length - 1)
			} else if (window.scrollY > limit - 60 && window.scrollY < limit) {
				setSelected(orixas.length - 2)
			} else {
				orixas.map((orixa, key) => {
					if (orixas[key + 1]) {
						if (window.scrollY > orixa.ref.current.offsetTop && window.scrollY < orixas[key + 1].ref.current.offsetTop - 200) {
							setSelected(key)
						}
					}
				});
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', eventListener);
		return () => window.removeEventListener('scroll', eventListener);
	}, [eventListener]);

	useEffect(() => {
		setTimeout(() => lockVerification.current = false, 300)
	}, [selected]);

	return (
		<Wrapper>
			<Col lg="2" md="3">
				<Sticky>
					{({ style, distanceFromTop }) => (
						<div style={{ ...style, top: '130px' }}>
							<NavBar vertical>
								{orixas.map((orixa, key) => {
									return (
										<NavBarItem selected={selected === key} key={`orixa_lista_${key}`}>
											<NavLink style={{ cursor: 'pointer' }} onClick={() => {
												lockVerification.current = true
												setSelected(key)
												window.scrollTo(0, orixa.ref.current.offsetTop - parseInt(theme.header.height) + 90)
											}}>{orixa.info(t).nombreSmall ? orixa.info(t).nombreSmall : orixa.info(t).nombre}</NavLink>
										</NavBarItem>)
								})
								}
							</NavBar>
						</div>
					)}
				</Sticky>

			</Col>
			<Col lg="10" md="9">
				<div>
					{orixas.map((orixa, key) => {
						return (
							<div ref={orixa.ref} className="orixas__element" key={`orixa_${key}`}>
								<Orixa
									t={t}
									nombre={orixa.info(t).nombre}
									sincretismo={orixa.info(t).sincretismo}
									color={orixa.info(t).color}
									planeta={orixa.info(t).planeta}
									dia={orixa.info(t).dia}
									img={orixa.info(t).img}
									vela={orixa.info(t).vela}
									descripcion={orixa.info(t).descripcion}
									saludo={orixa.info(t).saludo}
								/>
							</div>)
					})
					}
				</div>


				<HighlightTitle>
					{t("FRONTPAGE_TRINITY_TITLE")}
				</HighlightTitle>
				<HighlightList>
					<div>
						<HighlightListTitle> {t("FRONTPAGE_TRINITY_1")}</HighlightListTitle>
						<HighlightDesc>{t("FRONTPAGE_TRINITY_1_1")}</HighlightDesc>
					</div>
					<div>
						<HighlightListTitle>{t("FRONTPAGE_TRINITY_2")}</HighlightListTitle>
						<HighlightDesc>{t("FRONTPAGE_TRINITY_2_2")}</HighlightDesc>
					</div>
					<div>
						<HighlightListTitle>{t("FRONTPAGE_TRINITY_3")}</HighlightListTitle>
						<HighlightDesc>{t("FRONTPAGE_TRINITY_3_3")}</HighlightDesc>
					</div>
				</HighlightList>
			</Col>

		</Wrapper >


	)
}

export default Orixas

const Wrapper = styled(Row)`
`

const NavBar = styled(Nav)`
  line-height: 25px;
  letter-spacing: -1px;
	color: #333333;

`

const NavBarItem = styled(NavItem)`
  a {
    color: #7d7d7d;
    font-weight:600;

    &:hover{
      color: #3e9bae;
    }
  }

  ${({ selected }) => selected &&
		`
    font-size: 1.3rem;
      border-left: 4px solid #3e9bae;
      a {
        color: #3e9bae;
       font-weight: 900;
      }
      
    `
	}

`


const Highlight = styled.div`
 
`



const HighlightList = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
 
  div:not(:last-child) { 
    margin-bottom:10px;
  } 
`

const HighlightDesc = styled.div`
	color: #888;
  font-weight: bold;
  margin-bottom: 12px;
`

const HighlightListTitle = styled.div`
	color: #51b68c;
  font-size: 1.2rem;
  font-weight: bold;
`

const HighlightTitle = styled.div`
  color:  #3e9bae;
  font-weight: bold;
  text-align:center;
  line-height: 1.5em !important;
  letter-spacing: 2px;
  font-size: 40px;
  margin-bottom: 40px;
  border-bottom: 3px solid #3e9bae;
`