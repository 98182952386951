export default {
	name: 'Cáritas',
	content: [
		{
			type: 'content',
			text: 'Deus nosso Pai, que tendes poder e bondade, dai força àquele que passa pela provação, dai luz àquele que procura a verdade, ponde no coração do homem a compaixão e a caridade.'
		},
		{
			type: 'content',
			text: 'Deus, dai ao viajor a estrela guia, ao aflito a consolação, ao doente o repouso.'
		},
		{
			type: 'content',
			text: 'Pai, dai ao culpado o arrependimento, ao espírito a verdade, à criança o guia, ao órfão o pai.'
		},
		{
			type: 'content',
			text: 'Senhor, que a Vossa bondade se estenda sobre tudo o que criastes. Piedade, meu Deus, para aquele que Vos não conhece; esperança aquele que sofre.'
		},
		{
			type: 'content',
			text: 'Que a Vossa bondade permita aos Espíritos consoladores derramarem por toda a parte, a paz, a esperança e a fé.'
		},
		{
			type: 'content',
			text: 'Deus, um raio de luz, uma centelha de Vosso amor pode iluminar a Terra; deixai-nos beber nas fontes dessa bondade fecunda e infinita e todas as lágrimas secarão, todas as dores acalmar-se-ão; um só coração, um só pensamento, subirão até Vós, como um grito de reconhecimento e amor. Como Moisés sobre a montanha, nós Vos esperamos com os braços abertos,oh! poder, oh! bondade, oh! beleza, oh! perfeição, e queremos de algum modo alcançar a Vossa misericórdia.'
		},
		{
			type: 'content',
			text: 'Deus, dai-nos a força de ajudar o progresso a fim de subirmos até Vós; dai-nos a caridade pura, dai-nos a fé e a razão, dai-nos a simplicidade que fará das nossas almas o espelho onde se deve refletir a Vossa Imagem.'
		},
	]
}