
/**
 * Orixas scene
 *
 */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Nav, NavItem, NavLink } from "reactstrap";

import Orixa from "components/Orixa";
import Bento from "components/Icons/bento";
import { FiMoreVertical, FiX } from 'react-icons/fi';

//import config from 'config'
import { orixas } from './utils'

const Orixas = ({ t, theme }) => {
	const [selected, setSelected] = useState(0)

	const [openedMenu, setOpenedMenu] = useState(false)

	return (
		<>
			{orixas.map((orixa, key) => {
				return (
					<div ref={orixa.ref} className="orixas__element" key={`orixa_${key}`}>
						<Orixa
							t={t}
							nombre={orixa.info(t).nombre}
							sincretismo={orixa.info(t).sincretismo}
							color={orixa.info(t).color}
							planeta={orixa.info(t).planeta}
							dia={orixa.info(t).dia}
							img={orixa.info(t).img}
							vela={orixa.info(t).vela}
							descripcion={orixa.info(t).descripcion}
							saludo={orixa.info(t).saludo}
						/>
					</div>)
			})
			}

			<HighlightTitle>
				{t("FRONTPAGE_TRINITY_TITLE")}
			</HighlightTitle>
			<HighlightList>
				<div>
					<HighlightListTitle> {t("FRONTPAGE_TRINITY_1")}</HighlightListTitle>
					<HighlightDesc>{t("FRONTPAGE_TRINITY_1_1")}</HighlightDesc>
				</div>
				<div>
					<HighlightListTitle>{t("FRONTPAGE_TRINITY_2")}</HighlightListTitle>
					<HighlightDesc>{t("FRONTPAGE_TRINITY_2_2")}</HighlightDesc>
				</div>
				<div>
					<HighlightListTitle>{t("FRONTPAGE_TRINITY_3")}</HighlightListTitle>
					<HighlightDesc>{t("FRONTPAGE_TRINITY_3_3")}</HighlightDesc>
				</div>
			</HighlightList>

			<Menu opened={openedMenu}>
				<NavBar vertical>
					{orixas.map((orixa, key) => {
						return (
							<NavBarItem selected={selected === key} key={`orixa_lista_${key}`}>
								<NavLink style={{ cursor: 'pointer' }} onClick={() => {
									setOpenedMenu(false)
									setSelected(key)
									window.scrollTo(0, orixa.ref.current.offsetTop - parseInt(theme.header.height) - 90)
								}}>{orixa.info(t).nombreSmall ? orixa.info(t).nombreSmall : orixa.info(t).nombre}</NavLink>
							</NavBarItem>)
					})
					}
				</NavBar>
			</Menu>

			<FloatButton className="shadow-lg" onClick={() => { setOpenedMenu(!openedMenu) }}>
				{!openedMenu && <FiMoreVertical />}
				{openedMenu && <FiX />}
			</FloatButton>
		</>
	)
}

export default Orixas

const NavBar = styled(Nav)`
  line-height: 25px;
  letter-spacing: -1px;
  color: #333333;
`

const NavBarItem = styled(NavItem)`
  a {
    color: #7d7d7d;
    font-weight:600;

    &:hover{
      color: #3e9bae;
    }
  }

  ${({ selected }) => selected &&
		`
    font-size: 1.3rem;
      border-left: 4px solid #3e9bae;
      a {
        color: #3e9bae;
       font-weight: 900;
      }
      
    `
	}

`

const Menu = styled.div`
	position: fixed;
	background-color: #FFF;
	padding-top: calc(${({ theme }) => theme.header.height} + 40px);
	padding-left: 40px;
	top: ${({ opened }) => opened ? '0%' : '-100%'};
	left:0;
	right:0;
	height: 100%;
	transition: all 0.3s ease;
	overflow:auto;
`

const FloatButton = styled.div`
	position: fixed;
	bottom:30px;
	right:30px;
	background-color: #FFF;
	width:70px;
	height:70px;
	font-size: 42px;
	border-radius: 45px;
	cursor: pointer;
	display:flex;
	justify-content:center;
	align-items:center;
	color: #7c7c7d;
`

const Highlight = styled.div`
 
`



const HighlightList = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
 
  div:not(:last-child) { 
    margin-bottom:10px;
  } 
`

const HighlightDesc = styled.div`
	color: #888;
  font-weight: bold;
  margin-bottom: 12px;
`

const HighlightListTitle = styled.div`
	color: #51b68c;
  font-size: 1.2rem;
  font-weight: bold;
`

const HighlightTitle = styled.div`
  color:  #3e9bae;
  font-weight: bold;
  text-align:center;
  line-height: 1.5em !important;
  letter-spacing: 2px;
  font-size: 40px;
  margin-bottom: 40px;
  border-bottom: 3px solid #3e9bae;
`