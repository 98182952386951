// Import page components bellow here
// Keep them organized as the routes object

// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import Consultas from 'scenes/Consultas'
import Contactos from 'scenes/Contactos'
import Oraciones from 'scenes/Oraciones'
import Reinados from 'scenes/Reinados'
import Mandamientos from 'scenes/Mandamientos'
import Templos from 'scenes/Templos'
import Chakras from 'scenes/Chakras'
import Templo from 'scenes/Templo'
import Origen from 'scenes/Origen'
import Orixas from 'scenes/Orixas'
import Himno from 'scenes/Himno'
import Actividades from 'scenes/Actividades'
import Fiestas from 'scenes/Fiestas'
import Frontpage from 'scenes/Frontpage'

import config from 'config';

const { ROLES } = config;

// Define routes here
// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
const Routes = [
  {
    exact: true,
    key: 'consultas',
    name: 'Consultas',
    path: '/consultas',
    roles: [ROLES.GUEST],
    component: Consultas
  },
  {
    exact: true,
    key: 'contactos',
    name: 'Contactos',
    path: '/contactos',
    roles: [ROLES.GUEST],
    component: Contactos
  },
  {
    exact: false,
    key: 'oraciones',
    name: 'IOraciones',
    path: '/oraciones',
    roles: [ROLES.GUEST],
    component: Oraciones
  },
  {
    exact: true,
    key: 'reinados',
    name: 'Reinados',
    path: '/reinados',
    roles: [ROLES.GUEST],
    component: Reinados
  },
  {
    exact: true,
    key: 'mandamientos',
    name: 'Mandamientos',
    path: '/mandamientos',
    roles: [ROLES.GUEST],
    component: Mandamientos
  },
  {
    exact: true,
    key: 'templos',
    name: 'Templos',
    path: '/templos',
    roles: [ROLES.GUEST],
    component: Templos
  },
  {
    exact: false,
    key: 'chakras',
    name: 'Chakras',
    path: '/chakras',
    roles: [ROLES.GUEST],
    component: Chakras
  },
  {
    exact: true,
    key: 'templo',
    name: 'Templo',
    path: '/templo',
    roles: [ROLES.GUEST],
    component: Templo
  },
  {
    exact: true,
    key: 'origen',
    name: 'Origen',
    path: '/origen',
    roles: [ROLES.GUEST],
    component: Origen
  },
  {
    exact: true,
    key: 'orixas',
    name: 'Orixas',
    path: '/orixas',
    roles: [ROLES.GUEST],
    component: Orixas
  },
  {
    exact: true,
    key: 'himno',
    name: 'Himno',
    path: '/himno',
    roles: [ROLES.GUEST],
    component: Himno
  },
  {
    exact: true,
    key: 'actividades',
    name: 'Actividades',
    path: '/actividades',
    roles: [ROLES.GUEST],
    component: Actividades
  },
  {
    exact: true,
    key: 'fiestas',
    name: 'Fiestas',
    path: '/fiestas',
    roles: [ROLES.GUEST],
    component: Fiestas
  },
  {
    exact: true,
    key: 'frontpage',
    name: 'Frontpage',
    path: '/',
    roles: [ROLES.GUEST],
    component: Frontpage
  },
];

// Maps don't work on object convert it to an array
export default Routes;
