import { format_translations } from 'scenes/Chakras/utils'

export default (i18n) => {
	let content = format_translations(i18n, 'AJNA')

	content.splice(0, 0, {
		type: 'img',
		position: 'center',
		imgs: [
			{
				src: require("assets/images/chakras/ajna.png"),
				height: '100px',
				alt: 'Ajna'
			}
		]
	});

	return ({
		name: 'Ajna',
		content,
		url: 'ajna'
	})
}