/**
 * Story container
 *
 */

import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap';

//import config from 'config'

const Story = ({ title, body }) => {

  let hasLeftArea = false
  let hasRightArea = false

  _.each(body, el => {
    if (el.type === 'content' && el.imgs) {
      _.each(el.imgs, img => {
        if (img.position === 'left') {
          hasLeftArea = true
        }
        if (img.position === 'right') {
          hasRightArea = true
        }
      });
    }
  });

  const midAreaSize = (hasLeftArea || hasRightArea) ? "8" : "12"
  const leftImageSize = "2"
  const rightImageSize = "2"

  return (
    <Wrapper>
      {title &&
        <Row noGutters>
          {hasLeftArea && <Col lg={leftImageSize} />}
          <CenterWrapper lg={midAreaSize}>
            <Title>{title}</Title>
          </CenterWrapper>
          {hasRightArea && <Col lg="2" />}
        </Row>
      }
      {body.map((el, key) => {
        switch (el.type) {
          case 'intro':
            return (
              <ContentWrapper marginSmall noGutters>
                {hasLeftArea && <Col lg={leftImageSize} />}
                <CenterWrapper lg={midAreaSize}>
                  <Content>{el.text}</Content>
                </CenterWrapper>
                {hasRightArea && <Col lg={rightImageSize} />}
              </ContentWrapper>
            )
          case 'header':
            return (
              <Row noGutters>
                {hasLeftArea && <Col lg={leftImageSize} />}
                <CenterWrapper lg={midAreaSize}>
                  <Header>{el.text}</Header>
                </CenterWrapper>
                {hasRightArea && <Col lg={rightImageSize} />}
              </Row>
            )
          case 'content':
            return (
              <ContentWrapper noGutters>
                {hasLeftArea &&
                  <Col lg={leftImageSize}>
                    {el.imgs && el.imgs.map((img, key) =>
                      img.position === 'left' &&
                      <Row key={`content_img_left_${key}`} style={{ height: '100%' }}>
                        <Col lg="12" sm="12" style={{ height: '100%' }}>
                          <ImageWrapper>
                            <Image src={img.src} alt={img.caption} />
                            <ImageCaption>
                              {img.caption}
                            </ImageCaption>
                          </ImageWrapper>
                        </Col>
                      </Row>
                    )}
                  </Col>
                }


                <CenterWrapper lg={midAreaSize}>
                  <Content>{el.text}</Content>
                </CenterWrapper>


                {hasRightArea &&
                  <Col lg={rightImageSize}>
                    {el.imgs && el.imgs.map((img, key) =>
                      img.position === 'right' &&
                      <Row key={`content_img_right_${key}`} style={{ height: '100%' }}>
                        <Col lg="12" sm="12" style={{ height: '100%' }}>
                          <ImageWrapper>
                            <Image src={img.src} alt={img.caption} />
                            <ImageCaption>
                              {img.caption}
                            </ImageCaption>
                          </ImageWrapper>
                        </Col>
                      </Row>
                    )}
                  </Col>
                }

              </ContentWrapper>
            )
          case 'img':
            return (
              <ContentWrapper noGutters>
                {hasLeftArea && !el.fullWidth && <Col lg={leftImageSize} />}
                <CenterWrapper fullWidth={el.fullWidth} lg={el.fullWidth ? (midAreaSize + leftImageSize + rightImageSize) : midAreaSize}>
                  <CenterImageWrapper noGutters>
                    {el.imgs.map((img, key) =>
                      <CenterImageWrapperCol position={el.position} key={`img_area_${key}`}>
                        <CenterImage src={img.src} alt={img.caption} width={img.width} height={img.height} />
                        <CenterImageCaption>
                          {img.caption}
                        </CenterImageCaption>
                      </CenterImageWrapperCol>
                    )}
                  </CenterImageWrapper>
                </CenterWrapper>
                {hasRightArea && !el.fullWidth && <Col lg={rightImageSize} />}
              </ContentWrapper>
            )
          case 'li':

            let text = el.text.split(/\:/);
            let title = null
            let li = null

            if (text.length > 1) {
              title = text[0]
              li = text[1]
            } else {
              li = text[0]
            }

            return (
              <ContentWrapper noGutters>
                {hasLeftArea &&
                  <Col lg={leftImageSize}>
                    {el.imgs && el.imgs.map((img, key) =>
                      img.position === 'left' &&
                      <Row key={`content_img_left_${key}`} style={{ height: '100%' }}>
                        <Col lg="12" sm="12" style={{ height: '100%' }}>
                          <ImageWrapper>
                            <Image src={img.src} alt={img.caption} />
                            <ImageCaption>
                              {img.caption}
                            </ImageCaption>
                          </ImageWrapper>
                        </Col>
                      </Row>
                    )}
                  </Col>
                }


                <CenterWrapperLi lg={midAreaSize}>
                  {title && <LiTitle>{title}: </LiTitle>}<Li>{li}</Li>
                </CenterWrapperLi>


                {hasRightArea &&
                  <Col lg={rightImageSize}>
                    {el.imgs && el.imgs.map((img, key) =>
                      img.position === 'right' &&
                      <Row key={`content_img_right_${key}`} style={{ height: '100%' }}>
                        <Col lg="12" sm="12" style={{ height: '100%' }}>
                          <ImageWrapper>
                            <Image src={img.src} alt={img.caption} />
                            <ImageCaption>
                              {img.caption}
                            </ImageCaption>
                          </ImageWrapper>
                        </Col>
                      </Row>
                    )}
                  </Col>
                }

              </ContentWrapper>
            )
          default:
            return null
        }

      })}
    </Wrapper >
  )
}

export default Story

const Wrapper = styled.div`
`

const Title = styled.div`
font-size: 34px;
line-height: 64px;
margin-bottom: 50px;
letter-spacing: -2.5px;
color: #333333;
font-weight: 900;

`

const Header = styled.div`
  line-height: 32px;
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 1em;
  letter-spacing: -0.003em;
`

const ContentWrapper = styled(Row)`
  margin-bottom: ${({ noMargin, marginSmall }) => noMargin ? '0em' : marginSmall ? '1.4em' : '2em'};
`

const Content = styled.div`
  letter-spacing: -0.003em;
  line-height: 32px;
  font-size: 1.2rem;
  text-align: justify;
`


const LiTitle = styled.span`
  font-weight: bold;
`

const Li = styled.span`
`

const ImageWrapper = styled.div`
  display: flex;
  height: 100%;  
  flex-direction:column;
  align-items: center;
  padding-bottom: 20px;

  @media (max-width: 991px) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
`


const ImageCaption = styled.div`
  font-size: 12px;
  text-align: left;
`


const CenterWrapperLi = styled(Col)`
  @media (min-width: 767px) {
    padding: 0 50px !important;
  }
  @media (max-width: 767px) {
    padding: 0 10px !important;
  }
  height: 100%;

  letter-spacing: -0.003em;
  line-height: 32px;
  font-size: 1.2rem;
  text-align: justify;
  
`

const CenterWrapper = styled(Col)`

  ${({ fullWidth }) => !fullWidth && `
    @media (min-width: 767px) {
      padding: 0 50px !important;
    }
    @media (max-width: 767px) {
      padding: 0 10px !important;
    }
  `}
  height: 100%;
`

const Image = styled.div`
  background: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 6px;
  height: 100%;  
  background-position: center;
  width: 100%;

  @media (max-width: 991px) {
    height: 250px;  
  }
`

const CenterImage = styled.img`
  height: 100%;
  min-height: 100px;
  max-height: ${({ height }) => height ? height : '200px'};
  padding-bottom: 6px;
`

const CenterImageWrapper = styled(Row)`
height: 100 %;
`

const CenterImageWrapperCol = styled(Col)`
  text-align: ${ ({ position }) => position === 'left' ? 'left' : position === 'right' ? 'right' : 'center'};
  &:not(:last-child){
    margin-right: 30px;
  }
  margin-bottom: 20px;
`

const CenterImageCaption = styled.div`
font - size: 12px;
text - align: center;
`