import React from "react";
import styled from 'styled-components'
import { FiMail } from "react-icons/fi";
import {
	FacebookIcon,
	FacebookShareButton,
	RedditShareButton,
	WhatsappIcon,
	RedditIcon,
	TwitterIcon,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";


import {
	Navbar,
	NavbarBrand,
	Nav,
	NavItem,
	Container
} from "reactstrap";

const Footer = ({ t }) => {
	return (
		<FooterWrapper>
			<Container>
				<Navbar expand="md" style={{ position: 'unset' }}>

					<NavbarBrand>
						<SocialIcons>
							<SocialIcon>
								<FacebookShareButton
									url={'http://www.umbandatenerife.es/'}
									quote={t("SHARE_DESC")}
								>
									<FacebookIcon size={32} round />
								</FacebookShareButton>
							</SocialIcon>
							<SocialIcon>
								<WhatsappShareButton
									url={'http://www.umbandatenerife.es/'}
									title={t("SHARE_DESC")}
								>
									<WhatsappIcon size={32} round />
								</WhatsappShareButton>
							</SocialIcon>
							<SocialIcon>
								<TwitterShareButton
									url={'http://www.umbandatenerife.es/'}
									title={t("SHARE_DESC")}
								>
									<TwitterIcon size={32} round />
								</TwitterShareButton>
							</SocialIcon>
							<SocialIcon>
								<RedditShareButton
									url={'http://www.umbandatenerife.es/'}
									title={t("SHARE_DESC")}
									windowWidth={660}
									windowHeight={460}
								>
									<RedditIcon size={32} round />
								</RedditShareButton>
							</SocialIcon>
						</SocialIcons>
					</NavbarBrand>

					<Nav className="ml-auto align-items-center" navbar>

						<NavItem><FiMail />  <a href={`mailto:umbandaentenerife@hotmail.com`}>umbandaentenerife@hotmail.com</a></NavItem>
					</Nav>
				</Navbar>
			</Container>
		</FooterWrapper>
	);
}

export default Footer;

const FooterWrapper = styled.div`
	background-color: #f8f8f4;
	color: #51b68c;

	a{
		color: #333;
	}

	.navbar{
		padding: 25px 0;	
		border-top: 2px solid #3e9bae;

		.navbar-nav{
			font-weight: bold;
		}
	}
`

const SocialIcons = styled.div`
  display:flex;
`

const SocialIcon = styled.div`
  margin-right: 10px;
`

