import { format_translations } from 'scenes/Chakras/utils'

export default (i18n) => {
	let content = format_translations(i18n, 'SWADHISTHANA')

	content.splice(0, 0, {
		type: 'img',
		position: 'center',
		imgs: [
			{
				src: require("assets/images/chakras/swadhisthana.png"),
				height: '150px',
				alt: 'Swadhisthana'
			}
		]
	});

	return ({
		name: 'Swadhisthana',
		content,
		url: 'swadhisthana'
	})
}