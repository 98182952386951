export default {
	name: 'De los Médiuns',
	url: 'mediuns',
	content: [
		{
			type: 'content',
			text: 'Oh, DEUS de amor e misericórdia, dai a nós, médiuns do templo ______________ a compreensão perfeita da santidade da missão que nos foi confiada e da responsabilidade que nos cabe no desempenho destas funções! DEUS, nosso Pai, permiti que sintamos fortemente a influência invisível e salutar de nossos Guias e Protetores a fim de que possamos, mais facilmente, eliminar de nossos corações e dos nossos pensamentos todos os maus sentimentos e todas as imperfeições de que se acham imbuídos os nossos espíritos, a fim de que possamos subir até Vós!'
		},
		{
			type: 'content',
			text: 'Bons espíritos, que pela misericórdia de DEUS, permaneceis junto a nós, médiuns do Templo ____________, para nosso consolo; dai-nos um grande amor pelas virtudes!'
		},
		{
			type: 'content',
			text: 'Inspirai-nos tudo o que é bom! Afastai de nós os espíritos trevosos e maleficentes a fim de trilharmos, com melhor desempenho, o caminho luminoso que pode ser a nossa vida terrenal VIRGEM MÃE DA TERRA, rogai a DEUS por nós e pelos nossos irmãos desencarnados, que ainda se acham na ignorância, nas trevas e no sofrimento! Jesus, bom e amado Mestre Jesus, permiti que as falanges organizadas, que trabalham na prática do bem, possam nos dispensar bastante proteção, para que nossos trabalhos sejam coroados de êxito! Benditas e Louvadas sejam as forças da Criação! Salve São Miguel! São Gabriel! E São Rafael! Salve todas as Falanges da UMBANDA! Em nome de DEUS todo poderoso, de São Miguel Arcanjo e de nosso Anjo-daGuarda, eu me reverencio para a prática do BEM! Assim seja.'
		},
	]
}