/**
 * Chakras scene utils
 *
 */

import caritas from './content/caritas'
import credoUmbandista from './content/credoUmbandista'
import mediuns from './content/mediuns'
import sanFrancisco from './content/sanFrancisco'
import textoOjosCerrados from './content/textoOjosCerrados'

export const oraciones = [caritas, credoUmbandista, mediuns, sanFrancisco, textoOjosCerrados];
