/**
 * Actividades scene
 *
 */
import React from 'react'
import styled from 'styled-components'
import { Container, Table } from "reactstrap";
import moment from 'moment'

import Title from "components/Title";

import terreiro from 'assets/images/terreiro-de-umbanda.jpg';

//import config from 'config'

const Actividades = ({ t, i18n }) => {
  console.log()
  const weekdays = moment.weekdays();
  return (
    <Wrapper>
      <Container className="mt-5 pt-3">
        <Title>{t("ACTIVITIES_TITLE")}</Title>
        <Activities responsive className="mb-4" size="sm">
          <thead>
            <tr>
              <th
                style={{
                  width: "20%"
                }}
              >
                {weekdays[1]}
              </th>
              <th
                style={{
                  width: "10%"
                }}
              >
                {weekdays[2]}
              </th>
              <th
                style={{
                  width: "10%"
                }}
              >
                {weekdays[3]}
              </th>
              <th
                style={{
                  width: "20%"
                }}
              >
                {weekdays[4]}
              </th>
              <th
                style={{
                  width: "10%"
                }}
              >
                {weekdays[5]}
              </th>
              <th
                style={{
                  width: "10%"
                }}
              >
                {weekdays[6]}
              </th>
              <th
                style={{
                  width: "20%"
                }}
              >
                {weekdays[0]}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {t("ACTIVITIES_KINDER")}
              </td>
              <td />
              <td />
              <td>
                {t("ACTIVITIES_KINDER")}
              </td>
              <td />
              <td />
              <td>
                {t("ACTIVITIES_CONSULTATION")}
              </td>
            </tr>
          </tbody>
        </Activities>
        <div style={{ textAlign: 'center', color: '#333', fontWeight: 'bold', marginTop: '42px', fontSize: '1.1rem' }}>{t("ACTIVITIES_DESC")}</div>

        <BannerImage src={terreiro} />
      </Container>
    </Wrapper>
  )
}

export default Actividades

const Wrapper = styled.div``

const Activities = styled(Table)`
  padding:20px;
  background-color: transparent;
`


const BannerImage = styled.div`
margin-top: 60px;
  height:200px;
  background: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: 0px -250px;
  width: 100%;
`