

/**
 * Contactos scene
 *
 */
import React from 'react'

import Web from "./web";
import Mobile from "./mobile";

const Footer = (props) => {
	return (
		<>
			{props.screenType === 'web' && <Web  {...props} />}
			{props.screenType === 'mobile' && <Mobile  {...props} />}
		</>
	)
}

export default Footer
