/**
 * App reducers
 * Please write a description
 *
 */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: { name: 'Guest', role: 'guest' },
  isSidebarOpen: true,
  screenType: 'web'
};

export default createSlice({
  name: 'App',
  initialState,
  reducers: {
    onMount: (state, { payload }) => { },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setScreenType: (state, { payload }) => {
      state.screenType = payload;
    },
    navigateTo: (state, { payload }) => { },
    navigateBack: (state, { payload }) => { },
    updateUserRole: (state, { payload }) => { },
    toggleSidebar: (state, { payload }) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
  },
});
