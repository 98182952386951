import { format_translations } from 'scenes/Chakras/utils'

export default (i18n) => {
	let content = format_translations(i18n, 'MANIPURA')

	content.splice(0, 0, {
		type: 'img',
		position: 'center',
		imgs: [
			{
				src: require("assets/images/chakras/manipura.png"),
				height: '150px',
				alt: 'Manipura'
			}
		]
	});

	return ({
		name: 'Manipura',
		content,
		url: 'manipura'
	})
}