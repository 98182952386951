/**
 * Contactos scene
 *
 */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FiPhone, FiSend, FiMail, FiMapPin } from "react-icons/fi";
import { AiOutlineQrcode } from "react-icons/ai";
import {
	FacebookIcon,
	FacebookShareButton,
	RedditShareButton,
	WhatsappIcon,
	RedditIcon,
	TwitterIcon,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";

import QrCode from 'assets/images/qrcode.jpeg'

import Map from 'containers/Map'

import Title from "components/Title";

import { temples } from './utils'
//import config from 'config'

const Contactos = ({ screenSize, screenType, t, i18n }) => {
	const [contact, setContact] = useState('')
	const [desc, setDesc] = useState('')
	const [modal, setModal] = useState(false);

	const onFormSubmit = (e, data) => {
		e.preventDefault()
		window.location.href = `mailto::${temples[0].mail}?subject=Info&body=${desc}\n${contact}`
	}

	const toggle = () => setModal(!modal);

	return (
		<Wrapper className="mt-5 pt-3">
			<Container>
				<Title>{t("CONTACTS")}</Title>
				<Row>
					<Col lg={6} className="mb-5">
						<Map initialZoom={8} temples={temples} centerCoord={temples[0].coordinates} screenSize={{ height: 400 }} />
						<MapFooter>
							<div>
								<div style={{ marginBottom: '5px' }}>
									<FiMail /> <a href={`mailto:${temples[0].mail}`}>{temples[0].mail}</a>
								</div>
								<div style={{ marginBottom: '5px' }}>
									<FiMapPin /> <a target="_blank" href={`https://www.google.pt/maps/place/${temples[0].coordinates[1]},${temples[0].coordinates[0]}`}>{`${temples[0].coordinates[1]},${temples[0].coordinates[0]}`}</a>
								</div>
							</div>
							<Button onClick={toggle}><AiOutlineQrcode />QRCode</Button>
						</MapFooter>
					</Col>

					<Col lg={6} className="mb-5">
						<Form onSubmit={onFormSubmit}>
							<FormGroup>
								<Label for="exampleText"> {t("CONTACTS_MAIL_LABEL")}</Label>
								<Input rows="8" type="textarea" name="text" value={desc} onChange={(e) => setDesc(e.target.value)} id="exampleText" placeholder={t("CONTACTS_MAIL_PLACEHOLDER")} />
							</FormGroup>

							<FormGroup>
								<Label for="exampleEmail">{t("CONTACTS_PHONE_LABEL")}</Label>
								<Input type="email" name="email" id="exampleEmail" value={contact} onChange={(e) => setContact(e.target.value)} placeholder={t("CONTACTS_PHONE_PLACEHOLDER")} />
							</FormGroup>
							<Button type='submit'><FiSend /> {t("CONTACTS_SUBMIT")}</Button>
						</Form>
					</Col>
				</Row>
			</Container>

			<WrapperModal isOpen={modal} toggle={toggle} >
				<ModalHeader toggle={toggle}>{t("SHARE")} QRCode</ModalHeader>
				<BodyModal>
					<ImgQr src={QrCode} />
				</BodyModal>
				<FooterModal>
					<SocialIcons>
						<SocialIcon>
							<FacebookShareButton
								url={'https://i.imgur.com/Yyn81Q8.jpg'}
								quote={t("SHARE_DESC")}
							>
								<FacebookIcon size={32} round />
							</FacebookShareButton>
						</SocialIcon>
						<SocialIcon>
							<WhatsappShareButton
								url={'https://imgur.com/Yyn81Q8'}
								title={t("SHARE_DESC")}
							>
								<WhatsappIcon size={32} round />
							</WhatsappShareButton>
						</SocialIcon>
						<SocialIcon>
							<TwitterShareButton
								url={'https://i.imgur.com/Yyn81Q8.jpg'}
								title={t("SHARE_DESC")}
							>
								<TwitterIcon size={32} round />
							</TwitterShareButton>
						</SocialIcon>
						<SocialIcon>
							<RedditShareButton
								url={'https://i.imgur.com/Yyn81Q8.jpg'}
								title={t("SHARE_DESC")}
								windowWidth={660}
								windowHeight={460}
							>
								<RedditIcon size={32} round />
							</RedditShareButton>
						</SocialIcon>
					</SocialIcons>
					<div>
						<Link to={QrCode} target="_blank" download><Button color="primary" onClick={toggle}>{t("DOWNLOAD")}
						</Button></Link>{' '}
						<Button color="secondary" onClick={toggle}>{t("CANCEL")}</Button>
					</div>

				</FooterModal>
			</WrapperModal>

		</Wrapper>
	)
}

export default Contactos

const Wrapper = styled.div`
label, svg{
  color: #51b68c;
}

a{
  color: #333;
}

button{
  svg{
    color: #FFF;
  }

  background-color: #51b68c;
  border-color:  #51b68c;

  &:hover{
    background-color: #35755a;
    border-color:  #35755a;
  }

  &:active{
    background-color: #51b68c !important;
    border-color:  #35755a !important;
  }

   &:focus{
    background-color: #51b68c !important;
    border-color:  #35755a !important;
  }
}
`

const WrapperModal = styled(Modal)`

color: #333;

a{
  color: #333;
}

button{
  &.btn-primary{
    background-color: #51b68c;
    border-color:  #51b68c;

    &:hover{
      background-color: #35755a;
      border-color:  #35755a;
    }

    &:active{
      background-color: #51b68c !important;
      border-color:  #35755a !important;
    }
  
     &:focus{
      background-color: #51b68c !important;
      border-color:  #35755a !important;
    }
  }


}
`

const ImgQr = styled.img`
  width: 100%;
  max-width: 400px;

`

const BodyModal = styled(ModalBody)`
  display: flex;
  justify-content:center;
  align-items:center;
`

const MapFooter = styled.div`
  margin-top: 10px;
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
`

const FooterModal = styled(ModalFooter)`
  justify-content: space-between;
`

const SocialIcons = styled.div`
  display:flex;
`

const SocialIcon = styled.div`
  margin-right: 10px;
`