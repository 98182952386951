/**
 * Contactos scene utils
 *
 */


export const temples = [
	{
		coordinates: [-16.343161, 28.486905],
		name: 'Templo de Tenerife',
		details: 'También bajo la directriz del Babalorixá Heber de Oxalá, este templo es llevado de la mano del PDS Diego Gutierrez de Xango',
		mail: 'umbandaentenerife@hotmail.com',
		url: 'http://www.umbandatenerife.es/'
	}
]