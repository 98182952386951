import * as React from "react"

function SvgComponent(props) {
	return (
		<svg viewBox="0 0 292 512" width={292} height={512} {...props}>
			<path fill={props.fill} color="#000" d="M24.807 239.54h251v250h-251z" />
			<path fill={'#000'} d="M272.131 223.205c-12.58-9.361-28.409-12.147-43.431-7.642a288.165 288.165 0 01-67.887 11.784v-22.858c37.182-6.993 65.408-39.688 65.408-78.87 0-17.665-10.717-41.833-32.762-73.886-15.848-23.04-32.465-42.597-35.625-46.273A15.648 15.648 0 00145.95 0c-4.574 0-8.905 1.99-11.875 5.45-3.17 3.687-19.787 23.243-35.634 46.283-22.046 32.053-32.762 56.221-32.762 73.886 0 39.085 28.085 71.717 65.129 78.82v22.894a288.224 288.224 0 01-67.58-11.77c-15.02-4.503-30.85-1.719-43.432 7.642C7.215 232.566 0 246.93 0 262.611v203.024c0 25.566 20.799 46.364 46.364 46.364h199.199c25.565 0 46.364-20.799 46.364-46.364V262.611c0-15.682-7.215-30.045-19.796-39.406zM95.684 125.62c0-17.052 23.831-54.659 50.266-87.525 26.432 32.86 50.266 70.472 50.266 87.525 0 22.545-14.92 41.669-35.402 48.022v-20.578c0-8.285-6.717-15.003-15.003-15.003-8.285 0-15.003 6.717-15.003 15.003v20.488c-20.34-6.441-35.124-25.491-35.124-47.932zm166.238 340.016c0 9.021-7.338 16.359-16.359 16.359H46.365c-9.021 0-16.359-7.339-16.359-16.359V262.611c0-6.103 2.807-11.691 7.703-15.334 3.359-2.499 7.313-3.795 11.353-3.795 1.847 0 3.713.271 5.547.821 59.763 17.923 122.946 17.923 182.71 0 5.845-1.755 12.004-.67 16.899 2.974 4.896 3.643 7.703 9.231 7.703 15.334v203.025z" />
		</svg>
	)
}

export default SvgComponent
