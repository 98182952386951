/**
 * Templos scene utils
 *
 */

export const temples = [
  {
    coordinates: [-80.3137659, 25.8198898],
    name: "Templo Oxalá Miami, USA",
    details:
      "En la ciudad de Miami, USA, desde el año 2001 funciona el Templo de Oxalá, también regentado bajo la mano del Babalorixá Heber de Oxalá y el PDS Encargado es Victor Ducharne de Xango",
    url: "http://www.umbandahu.com/",
  },
  {
    coordinates: [-66.897838, 10.519201],
    name: "El Templo Oxalá Caracas, Venezuela",
    details:
      "Primer templo de la religión en Venezuela, ubicado en Caracas en la Urb San Bernardino. Desde allí salen todas las directrices principales hacia los demás templos del Babalorixá Heber Ureta de Oxalá Templo",
    contact: "+58 212 552 70 32",
    url: "http://www.umbandavenezuela.com.ve/",
  },
  {
    coordinates: [-16.343161, 28.486905],
    name: "Templo de Tenerife",
    details:
      "También bajo la directriz del Babalorixá Heber de Oxalá, este templo es llevado de la mano del PDS Diego Gutierrez de Xango",
    mail: "umbandaentenerife@hotmail.com",
    url: "http://www.umbandatenerife.es/",
  },
];
