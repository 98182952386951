/**
 * Orixas scene
 *
 */
import React from 'react'
import styled from 'styled-components'
import { Container, Nav, NavItem } from "reactstrap";
import { StickyContainer } from 'react-sticky';

import Title from "components/Title";

//import config from 'config'
import Web from "./web";
import Mobile from "./mobile";

const Orixas = ({ t, screenType, ...props }) => {

  return (
    <StickyContainer>
      <Wrapper className="mt-5 pt-3">
        <Title>
          {t('ORIXAS_TITLE')}
        </Title>
        {screenType === 'web' && <Web t={t} {...props} />}
        {screenType === 'mobile' && <Mobile t={t}  {...props} />}
      </Wrapper>
    </StickyContainer>

  )
}

export default Orixas

const Wrapper = styled(Container)`
`
