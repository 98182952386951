/**
 * Origen scene utils
 *
 */

export const body = t => {
	return ([
		{
			type: 'intro',
			text: t("ORIGIN_DESC_1")
		},
		{
			type: 'header',
			text: t("ORIGIN_HEADER_1")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_2")

		},
		{
			type: 'header',
			text: t("ORIGIN_HEADER_2")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_3"),
			imgs: [
				{
					caption: t("ORIGIN_CAPTION_ZELIO"),
					position: 'left',
					src: require("assets/images/zelio4.png"),
					alt: t("ORIGIN_CAPTION_ZELIO")
				}
			],
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_4")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_5")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_6")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_7")
		},
		{
			type: 'img',
			imgs: [
				{
					caption: t("ORIGIN_CAPTION_ZELIO_HOUSE"),
					src: require("assets/images/sede.jpg"),
					alt: t("ORIGIN_CAPTION_ZELIO_HOUSE")
				}
			]
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_8"),
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_9")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_10")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_11"),
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_12")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_13")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_14"),
			imgs: [
				{
					caption: t("ORIGIN_CAPTION_ZELIO_WIFE"),
					position: 'right',
					src: require("assets/images/zelioemulher.jpg"),
					alt: t("ORIGIN_CAPTION_ZELIO_WIFE"),
				}
			],
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_15")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_16")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_17")
		},
		{
			type: 'header',
			text: t("ORIGIN_HEADER_3")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_18")
		},
		{
			type: 'content',
			text: t("ORIGIN_DESC_19")

		}
	])
}
