import React, { useState } from "react";
import styled from 'styled-components'
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Container,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu
} from "reactstrap";
import { MdTranslate } from 'react-icons/md';
import moment from 'moment'
import { useHistory } from "react-router-dom";

import logoSmall from "assets/icons/logoSmall.png";

const Header = ({ t, i18n }) => {
	let history = useHistory();


	const [isOpen, setOpen] = useState(false);
	const [isOpenDrop, updateIsOpen] = useState(false);
	const [isOpenDrop2, updateIsOpen2] = useState(false);
	const [isOpenDrop3, updateIsOpen3] = useState(false);

	return (
		<HeaderWrapper fixed="top" color="light" light expand="md" className="shadow-sm header">
			<Container>

				<NavbarBrand
					style={{
						display: "flex",
						alignItems: "center",
						cursor: "pointer"
					}}
					onClick={() => history.push("/")}
				>
					<Logo src={logoSmall} alt="Umbanda" />

					<div
						style={{
							display: "flex",
							alignItems: "baseline"
						}}
					>
						<div
							style={{
								fontSize: "1.5rem",
								marginRight: "3px"
							}}
						>
							UMBANDA
						</div>
					</div>
				</NavbarBrand>
				<Toggler onClick={() => setOpen(!isOpen)} />
				<NavCollapse isOpen={isOpen} navbar>
					<Nav className="ml-auto align-items-center" navbar>
						<NavItem>
							<NavLink onClick={() => history.push("/")}>{t("HEADER_START")}</NavLink>
						</NavItem>
						<UncontrolledDropdown
							onMouseOver={() => updateIsOpen(true)}
							onMouseLeave={() => updateIsOpen(false)}
							toggle={() => updateIsOpen(!isOpenDrop)}
							isOpen={isOpenDrop}
							nav
							inNavbar
						>
							<DropdownToggle nav caret>
								{t("HEADER_US")}
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem onClick={() => history.push("/templo")}>
									{t("HEADER_US_TEMPLE")}
								</DropdownItem>
								<DropdownItem onClick={() => history.push("/actividades")}>
									{t("HEADER_US_ACTIVITIES")}
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
						<UncontrolledDropdown
							onMouseOver={() => updateIsOpen2(true)}
							onMouseLeave={() => updateIsOpen2(false)}
							toggle={() => updateIsOpen2(!isOpenDrop2)}
							isOpen={isOpenDrop2}
							nav
							inNavbar
						>
							<DropdownToggle nav caret>
								{t("HEADER_UMBANDA")}
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem onClick={() => history.push("/origen")}>
									{t("HEADER_UMBANDA_ORIGIN")}
								</DropdownItem >
								<DropdownItem onClick={() => history.push("/templos")} > {t("HEADER_UMBANDA_TEMPLES")}</DropdownItem>
								<DropdownItem onClick={() => history.push("/fiestas")}>
									{t("HEADER_UMBANDA_FESTIVITIES")}
								</DropdownItem>
								<DropdownItem onClick={() => history.push("/himno")}>
									{t("HEADER_UMBANDA_ANTHEM")}
								</DropdownItem>
								<DropdownItem onClick={() => history.push("/mandamientos")}>
									{t("HEADER_UMBANDA_COMMANDEMENTS")}
								</DropdownItem>
								<DropdownItem onClick={() => history.push("/orixas")}>
									{t("HEADER_UMBANDA_ORIXAS")}
								</DropdownItem>
								<DropdownItem onClick={() => history.push("/reinados")}>{t("HEADER_UMBANDA_REIGNS")}</DropdownItem>
								<DropdownItem onClick={() => history.push("/chakras")}>{t("HEADER_UMBANDA_CHAKRAS")}</DropdownItem>
								<DropdownItem onClick={() => history.push("/oraciones")}>{t("HEADER_UMBANDA_PRAYERS")}</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
						<NavItem>
							<NavLink onClick={() => history.push("/consultas")}>{t("HEADER_UMBANDA_CONSULTATIONS")}</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={() => history.push("/contactos")}>{t("HEADER_UMBANDA_CONTACTS")}</NavLink>
						</NavItem>
						<UncontrolledDropdown
							onMouseOver={() => updateIsOpen3(true)}
							onMouseLeave={() => updateIsOpen3(false)}
							toggle={() => updateIsOpen3(!isOpenDrop3)}
							isOpen={isOpenDrop3}
							nav
							inNavbar
						>
							<DropdownToggle nav caret>
								<MdTranslate /> {t("HEADER_UMBANDA_IDIOM")}
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem onClick={(e) => {
									e.preventDefault();
									moment.locale('es');
									i18n.changeLanguage('es')
								}}>
									Español
								</DropdownItem >
								<DropdownItem onClick={(e) => {
									e.preventDefault();
									moment.locale('pt');
									i18n.changeLanguage('pt')
								}}>
									Português
								</DropdownItem >
								<DropdownItem onClick={(e) => {
									e.preventDefault();
									moment.locale('en-gb');
									i18n.changeLanguage('en')
								}}>
									English
								</DropdownItem >
							</DropdownMenu>
						</UncontrolledDropdown>

					</Nav>
				</NavCollapse>
			</Container>
		</HeaderWrapper>
	);
}

export default Header;

const HeaderWrapper = styled(Navbar)`
	height: ${({ theme }) => theme.header.height};


`

const NavCollapse = styled(Collapse)`
	background-color: #f8f9fa;
	@media (max-width: 767px) {
		box-shadow:0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
		position:absolute;
		top: ${({ theme }) => theme.header.height};
		left:0;
		right:0;
  }

	&:active{
		outline:none !important;
	}


	a:hover{
		color:#51b68c !important;
	}

	.dropdown-menu {
		a:hover{
			color: white  !important;
			background-color: #51b68c !important;
		}
		a:active{
			background-color: #35755a !important;
		}
	}



	a:active{
		outline:none !important;
	}

	a{
		cursor: pointer;
		outline:none !important;
	}

	.dropdown-menu{
		@media (min-width: 767px) {
			box-shadow:0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
		}
		top: 40px;
		padding-top: 10px;
    border: none;
		background-color: #f8f9fa;
		border-radius:0;
	
		.dropdown-item{
			outline: none;
			:hover{
				color: white  !important;
				background-color: #51b68c !important;
			}
			:active{
				background-color: #35755a !important;
			}
		}
	}


`

const Logo = styled.img`
	height: 40px;
	margin-right:20px;
  width: auto;
`

const Toggler = styled(NavbarToggler)`
	border: none;
	outline: none;

	&:focus{
		outline: none;
	}
`