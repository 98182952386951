import { combineReducers } from 'redux';

import appSlice from 'app/logic/slice';

// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports heree'

export const rootReducer = combineReducers({
  App: appSlice.reducer,
});

export const actions = {
  App: appSlice.actions,
};
