/**
 * Orixa component
 *
 */

import React from 'react'
import styled from 'styled-components'
import { Row, Col } from "reactstrap";

import Candle from 'components/Icons/candle'

const Orixa = ({ t, nombre, img, sincretismo, descripcion, color, saludo, planeta, dia, vela }) => {
  return (
    <Wrapper className="shadow-sm">
      <Row noGutters>
        <Col lg="3">
          <Img src={img || "https://via.placeholder.com/200x250"} />
        </Col>
        <Col lg="9">
          <ContentWrapper lg="9">
            <h2>{nombre}</h2>
            <div style={{ marginBottom: '8px' }}>{descripcion}</div>
            {sincretismo && <div><Bold>{t("ORIXAS_SINC")}:</Bold> {sincretismo}</div>}
            <ItemsRow>
              <ItemCol>
                {vela &&
                  <>
                    <Candle
                      fill={color}
                      style={{ paddingRight: '8px' }}
                      height="25px"
                      width="25px"
                    />
                    {vela}
                  </>
                }
              </ItemCol>
              <ItemCol>
                {saludo &&
                  <>
                    <Icon
                      height="25px"
                      width="auto"
                      src={require("assets/icons/handshake.svg")}
                    />
                    {saludo}
                  </>
                }
              </ItemCol>
              <ItemCol>
                {(planeta || dia) &&
                  <>
                    <Icon
                      height="25px"
                      width="auto"
                      src={require("assets/icons/saturn.svg")}
                    />
                    {planeta && planeta}
                    {(planeta && dia) && ' | '}
                    {dia}
                  </>
                }
              </ItemCol>

            </ItemsRow>
          </ContentWrapper>
        </Col>

      </Row>
    </Wrapper>
  )
}

export default Orixa

const Wrapper = styled.div`
  margin-bottom: 30px;
  background-color: #fff;
`

const Img = styled.div`
  padding: 5px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height:100%;
  min-height: 180px;
`

const Bold = styled.span`
  font-weight: bold;
  font-color: #333;
`


const ContentWrapper = styled.div`
  padding: 20px 30px 30px 30px;
`

const ItemsRow = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-top: 18px;
`

const ItemCol = styled.div`
  align-items: center;
  display: flex;
  line-height: 0;
  
  &:not(:first-child){
    padding-left:24px;
  }
`

const Icon = styled.img`
  padding-right:8px;
`
