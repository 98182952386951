/**
 * Oraciones scene
 *
 */
import React, { useState } from 'react'
import styled from 'styled-components'
import {
	useRouteMatch,
	Switch,
	Route,
	Link
} from "react-router-dom";
import { Row, Nav, NavItem } from "reactstrap";

import Story from 'containers/Story'

import Bento from "components/Icons/bento";
import { FiMoreVertical, FiX } from 'react-icons/fi';


//import config from 'config'
import { oraciones } from './utils'

const Oraciones = ({ t, i18n }) => {
	const [selected, setSelected] = useState(0)
	const [openedMenu, setOpenedMenu] = useState(false)

	let { path, url } = useRouteMatch();

	return (
		<>
			<Switch>
				{oraciones.map((oracion, key) => {
					if (oracion.url) {
						return (
							<Route
								key={`oracion_lista2_${key}`}
								render={() => <Story
									title={oracion.name}
									body={oracion.content}
								/>}
								path={`${path}/${oracion.url}`}
							/>
						)
					} else {
						return (
							<Route
								key={`oracion_lista2_${key}`}
								exact path={path}
								render={() => <Story
									title={oracion.name}
									body={oracion.content}
								/>}
							/>
						)
					}
				})}
			</Switch>
			<Menu opened={openedMenu}>
				<NavBar vertical>
					{oraciones.map((oracion, key) => {
						return (
							<NavBarItem selected={selected === key} key={`oracion_lista_${key}`}>
								{
									oracion.url && <NavBarLink onClick={() => {
										window.scrollTo(0, 0);
										setOpenedMenu(false)
										setSelected(key)
									}} to={`${url}/${oracion.url}`}>{oracion.name}</NavBarLink>
								}
								{
									!oracion.url && <NavBarLink onClick={() => {
										window.scrollTo(0, 0);
										setOpenedMenu(false)
										setSelected(key)
									}} to={url}>{oracion.name}</NavBarLink>
								}
							</NavBarItem>)
					})}
				</NavBar>
			</Menu>

			<FloatButton className="shadow-lg" onClick={() => { setOpenedMenu(!openedMenu) }}>
				{!openedMenu && <FiMoreVertical />}
				{openedMenu && <FiX />}
			</FloatButton>

		</>
	)
}

export default Oraciones

const NavBar = styled(Nav)`
  line-height: 25px;
  letter-spacing: -1px;
  color: #333333;
`

const NavBarItem = styled(NavItem)`
  a {
    color: #7d7d7d;
    font-weight:600;

    &:hover{
      color: #3e9bae;
    }
  }

  ${({ selected }) => selected &&
		`
      font-size: 1.3rem;
      border-left: 4px solid #3e9bae;
      a {
        color: #3e9bae;
       font-weight: 900;
      }
      
    `
	}
`

const NavBarLink = styled(Link)`
  display: block;
  text-decoration: none !important;
  padding: 0.5rem 1rem;
  background-color: transparent;
`


const Menu = styled.div`
	position: fixed;
	background-color: #FFF;
	padding-top: calc(${({ theme }) => theme.header.height} + 40px);
	padding-left: 40px;
	top: ${({ opened }) => opened ? '0%' : '-100%'};
	left:0;
	right:0;
	height: 100%;
	transition: all 0.3s ease;
`

const FloatButton = styled.div`
	position: fixed;
	bottom:30px;
	right:30px;
	background-color: #FFF;
	width:70px;
	height:70px;
	font-size: 42px;
	border-radius: 45px;
	cursor: pointer;
	display:flex;
	justify-content:center;
	align-items:center;
	color: #7c7c7d;
`