/**
 * Templo scene utils
 *
 */

export const body = (t, screenType) => {
  return [
    {
      type: "img",
      position: "center",
      fullWidth: true,
      imgs: [
        {
          src: require("assets/images/templo2024.jpg"),
          width: screenType === "mobile" ? "100%" : "60%",
          height: "auto",
          alt: "Umbanda Tenerife Congal",
        },
      ],
    },
    {
      type: "intro",
      text: t("TEMPLE_DESC_1"),
    },
    {
      type: "content",
      text: t("TEMPLE_DESC_2"),
      imgs: [
        {
          caption: t("TEMPLE_CAPTION_HEBER"),
          position: "left",
          src: require("assets/images/heber3.jpg"),
          alt: t("TEMPLE_CAPTION_HEBER"),
        },
      ],
    },
    {
      type: "img",
      imgs: [
        {
          caption: t("TEMPLE_CAPTION_JOAO"),
          src: require("assets/images/joaodebara.jpg"),
          alt: t("TEMPLE_CAPTION_JOAO"),
        },
        {
          caption: t("TEMPLE_CAPTION_HIPOLITA"),
          src: require("assets/images/maeteta.jpg"),
          alt: t("TEMPLE_CAPTION_HIPOLITA"),
        },
      ],
    },
    {
      type: "content",
      text: t("TEMPLE_DESC_3"),
      imgs: [
        {
          caption: t("TEMPLE_CAPTION_DIEGO"),
          position: "right",
          src: require("assets/images/diego2019.jpeg"),
          alt: t("TEMPLE_CAPTION_DIEGO"),
        },
      ],
    },
  ];
};
