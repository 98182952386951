/**
 * ColorBanner&#39; container
 *
 */

import React from 'react'
import styled from 'styled-components'

import {
  Container,
  Row,
  Col
} from "reactstrap";

//import config from 'config'

const ColorBanner = ({ title, description, color, icon }) => {

  return (
    <Wrapper color={color}>
      <Container>
        <Row className="pt-5 pb-5">
          <Col md="2" lg="2" style={{ textAlign: 'center', marginBottom: '20px', maxHeight: '100px' }}>
            <Image color={color}>{icon}</Image>
          </Col>

          <Col md="10" lg="10" style={{ borderLeft: `4px solid ${color}` }} >
            <div className="h5 " style={{ color: "#333", fontWeight: "bold" }}>{title}</div>
            {description}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default ColorBanner;

const Wrapper = styled.div`
`

const Image = styled.div`
  width: auto;
  height: 100%;

  svg {
    fill:  ${({ color }) => color};
    stroke: ${({ color }) => color};
    width: 100%;
    height: 100%;
  }
` 