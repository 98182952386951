import { all } from 'redux-saga/effects';

import appSaga from 'app/logic/sagas';

// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here

// WARNING!! DO NOT CHANGE STRUCTURE OR ELSE HYGEN WON'T WORK
export default function* rootSaga() {
  yield all([
    appSaga()
  ]);
}
