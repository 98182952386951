/**
 * Title component
 *
 */

import React from 'react'
import styled from 'styled-components'

const Title = ({ children }) => {
  return (
    <Wrapper>{children}</Wrapper>
  )
}

export default Title


const Wrapper = styled.div`
  font-size: 40px;
  line-height: 64px;
  margin-bottom: 50px;
  letter-spacing: -2.5px;
  color: #333333;
  font-weight: 900;

  &::after
  {
    content: '';
    border-bottom: 3px solid #3e9bae;
    width: 130px;
    display: block;
}
`
