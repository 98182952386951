/**
 * Origen scene
 *
 */
import React from 'react'
import styled from 'styled-components'
import { Container } from "reactstrap";

import Story from 'containers/Story'

import Title from "components/Title";

import { body } from './utils'
//import config from 'config'

const Origen = ({ t, i18n }) => {

	return (
		<Wrapper>
			<Container className="mt-5 pt-5 ">
				<Title>
					{t("ORIGIN_TITLE")}
				</Title>
				<Story
					body={body(t)}
				/>
			</Container >
		</Wrapper >
	)
}

export default Origen

const Wrapper = styled.div``