/**
 * Chakras scene utils
 *
 */

import intro from './content/intro'
import ajna from './content/ajna'
import anahata from './content/anahata'
import manipura from './content/manipura'
import muladhara from './content/muladhara'
import sahasrara from './content/sahasrara'
import swadhisthana from './content/swadhisthana'
import visuddha from './content/visuddha'

export const chakras = (i18n) => [intro(i18n), muladhara(i18n), swadhisthana(i18n), manipura(i18n), anahata(i18n), visuddha(i18n), ajna(i18n), sahasrara(i18n)];


export const format_translations = (i18n, name) => {
	const language = i18n.language.substring(0, 2)
	const { translation } = i18n.getDataByLanguage(language);
	const KEYS = Object.keys(translation).filter(key => key.includes(name));

	return KEYS.map((key) => {
		if (key.includes('TITLE')) {
			return ({
				type: 'title',
				text: translation[key]
			})
		}
		if (key.includes('INTRO')) {
			return ({
				type: 'intro',
				text: translation[key]
			})
		}
		if (key.includes('HEADER')) {
			return ({
				type: 'header',
				text: translation[key]
			})
		}
		if (key.includes('DESC')) {
			return ({
				type: 'content',
				text: translation[key]
			})
		}
		if (key.includes('LI')) {
			return ({
				type: 'li',
				text: translation[key]
			})
		}
		return (null)
	})


}