/**
 * Himno scene
 *
 */
import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from "reactstrap";

import Title from "components/Title";

//import config from 'config'

const Himno = ({ screenType, t, i18n }) => {

	return (
		<Wrapper className="mt-5 pt-3">
			<Title>{t("ANTHEM_TITLE")}</Title>
			<Row>
				<ColLeft screenType={screenType} lg={4} md={6}>
					<iframe
						width="100%"
						height="232px"
						src="https://www.youtube.com/embed/25P3O948_gM"
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					/>
				</ColLeft>
				<ColRight screenType={screenType} lg={8} md={6}>
					<Hymn screenType={screenType} >
						Refletiu a luz divina
					<br />
								Com todo seu esplendor
					<br />
								Vem do reino de Oxalá
					<br />
								Onde há paz e amor
					<br />
								Luz que refletiu na terra
					<br />
								Luz que refletiu no mar
					<br />
								Luz que veio de Aruanda
					<br />
								Para tudo iluminar
					<br />A Umbanda é paz e amor
					<br />É um mundo cheio de luz
					<br />É a força que nos dá vida e a grandeza nos
								conduz.
					<br />
								Avante filhos de fé, <br />
								Como a nossa lei não há, <br />
								Levando ao mundo inteiro
					<br />A Bandeira de Oxalá !<br />
								Saravá!
			</Hymn>
				</ColRight>
			</Row>


			{/*	The Divine Light was reflected
							<br />
							With all its Glory
							<br />
							Coming from the Kigdom of Oxalá
							<br />
							Where there is Peace and Love
							<br />
							Light which reflected on the Earth
							<br />
							Light which reflected on the Ocean
							<br />
							Light which comes from Aruanda
							<br />
							To Everything enlighten
							<br />
							Umbanda is Peace and Love
							<br />A World full of light
							<br />
							It is the Strenght that gives us Life and greatness
							leads us.
							<br />
							Onward children of faith, <br />
							As us there is none, <br />
							Taking to the entire world
							<br />
							The flag of Oxalá !<br />
							Saravá!
			*/}
		</Wrapper >
	)
}

export default Himno

const Wrapper = styled(Container)`
`

const ColLeft = styled(Col)`
${({ screenType }) => screenType !== 'mobile' && `padding-right:40px;`}

`
const ColRight = styled(Col)`
${({ screenType }) => screenType !== 'mobile' && `border-left: 2px solid #51b68c;`}
`

const Hymn = styled.div`
${({ screenType }) => screenType == 'mobile' && `text-align: center;`}
${({ screenType }) => screenType == 'mobile' && `margin-top: 20px;`}
`