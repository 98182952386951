/**
 * Consultas scene
 *
 */
import React, { useState } from "react";

const Consultas = ({ screenSize, screenType, t, i18n }) => {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSfYC2O-U4QbwarUUU0L6-KpmH1rQnaDfUSh-Il9hHuuNunQ6A/viewform?embedded=true"
      width="100%"
      height="1540"
      scrolling="no"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  );
};

export default Consultas;
