/**
 * Orixas scene utils
 *
 */

import React from "react";

export const orixas = [
	{
		info: t => ({
			nombre: 'Oxalá - Obatala - Orixala',
			nombreSmall: 'Oxalá',
			sincretismo: t("ORIXAS_OXALA_SINC"),
			vela: 'Blanca',
			descripcion: t("ORIXAS_OXALA_DESC"),
			saludo: 'Ep Ep Baba',
			color: '#FFF',
			planeta: 'Sol',
			dia: 'Domingo',
			img: require('assets/images/orixas/oxala.jpg'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Xapanã Jubetei e Xapanã Beluja',
			nombreSmall: 'Xapanã',
			sincretismo: t("ORIXAS_XAPANA_SINC"),
			descripcion: t("ORIXAS_XAPANA_DESC"),
			vela: 'Rosada',
			color: '#F98B88',
			saludo: 'Atotô Babaluaye',
			dia: 'Viernes',
			img: require('assets/images/orixas/omulu.png'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Iroco',
			descripcion: t("ORIXAS_IROCO_DESC"),
			sincretismo: t("ORIXAS_IROCO_SINC"),
			vela: 'Morada',
			color: '#8673A1',
			saludo: 'Okê Arô/Iroko é',
			dia: 'Sábado',
			img: require('assets/images/orixas/iroco.png'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Exú',
			descripcion: t("ORIXAS_EXU_DESC"),
			vela: 'Negra y Roja',
			color: '#e94a34',
			dia: 'Lunes',
			saludo: 'Exú Ê',
			img: require('assets/images/orixas/exu.png'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Bará',
			descripcion: t("ORIXAS_BARA_DESC"),
			sincretismo: t("ORIXAS_BARA_SINC"),
			vela: 'Blanca',
			color: '#FFF',
			dia: 'Lunes',
			saludo: 'Exú Ê',
			img: require('assets/images/orixas/bara.jpg'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Pomba Gira',
			descripcion: t("ORIXAS_POMBA_DESC"),
			vela: 'Roja',
			color: '#e94a34',
			dia: 'Viernes',
			saludo: 'Pombi Ê',
			img: require('assets/images/orixas/pomba.jpg'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Pretos',
			descripcion: t("ORIXAS_PRETOS_DESC"),
			vela: 'Amarilla',
			color: '#f2c400',
			dia: 'Sábado',
			saludo: 'Atotô Babaluaye',
			img: require('assets/images/orixas/pretovelho.png'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Africanos',
			descripcion: t("ORIXAS_AFRICANOS_DESC"),
			color: '#f2c400',
			sincretismo: t("ORIXAS_AFRICANOS_SINC"),
			dia: 'Sábado',
			saludo: 'Atotô Babaluaye',
			vela: 'Amarilla',
			planeta: 'Saturno',
			img: require('assets/images/orixas/africano.jpg'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Oxossi',
			descripcion: t("ORIXAS_OXOSSI_DESC"),
			color: '#1caf5d',
			sincretismo: t("ORIXAS_OXOSSI_SINC"),
			dia: 'Miércoles',
			saludo: 'Okê Arô',
			vela: 'Verde',
			planeta: 'Mercurio',
			img: require('assets/images/orixas/oxossi.png'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Iansã',
			descripcion: t("ORIXAS_IANSA_DESC"),
			color: '#d28512',
			sincretismo: t("ORIXAS_IANSA_SINC"),
			dia: 'Jueves',
			saludo: 'Eparrei Oyá',
			vela: 'Naranja',
			img: require('assets/images/orixas/iansa.png'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Xangô',
			descripcion: t("ORIXAS_XANGO_DESC"),
			color: '#865711',
			sincretismo: t("ORIXAS_XANGO_SINC"),
			dia: 'Jueves',
			planeta: 'Jupiter',
			saludo: 'Kaô Cabecile',
			vela: 'Marrón',
			img: require('assets/images/orixas/xango.png'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Ogum',
			descripcion: t("ORIXAS_OGUM_DESC"),
			color: '#e94a34',
			sincretismo: t("ORIXAS_OGUM_SINC"),
			dia: 'Martes',
			planeta: 'Marte',
			saludo: 'Ogunhê Meu Pai/Patacorie',
			vela: 'Roja',
			img: require('assets/images/orixas/ogum.png')
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Nanã',
			descripcion: t("ORIXAS_NANA_DESC"),
			color: '#F98B88',
			sincretismo: t("ORIXAS_NANA_SINC"),
			dia: 'Lunes',
			saludo: 'Saluba Nanã',
			vela: 'Rosada',
			img: require('assets/images/orixas/nana.png')
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Oxum',
			descripcion: t("ORIXAS_OXUM_DESC"),
			color: '#f2c400',
			sincretismo: t("ORIXAS_OXUM_SINC"),
			dia: 'Viernes',
			saludo: 'Ora Ie Ie O',
			planeta: 'Vénus',
			vela: 'Amarilla',
			img: require('assets/images/orixas/oxum.png'),
		}),
		ref: React.createRef()
	},
	{
		info: t => ({
			nombre: 'Iemanjá',
			descripcion: t("ORIXAS_IEMANJA_DESC"),
			color: '#78D2FF',
			sincretismo: t("ORIXAS_IEMANJA_SINC"),
			dia: 'Lunes',
			saludo: 'Odoia',
			planeta: 'Luna',
			vela: 'Azul',
			img: require('assets/images/orixas/iemanja.png'),
		}),
		ref: React.createRef()
	}
];